import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { z } from 'zod'
import toast from 'react-hot-toast'

import { Button, InputErrMsg as ErrMsg, SectionContainer } from '../shared'
import { IUser } from '../Profile'
import { PROFILE_PERSONAL_INFO_UPDATED } from '../../../../config/language'
import { SecInput, SecInputLabel, UtilPrimButtonStyle } from '../../../../styles'
import { SET_USER_VALS } from '../../graphql'

const nameRegex = /^[A-Za-z]{1,}[a-zA-Z \\'\\`\\-]{1,}$/

interface IFormErrors {
  firstName: string | false
  lastName: string | false
}

const fldErrsDefaults: IFormErrors = {
  firstName: false,
  lastName: false,
}

const FormData = z.object({
  firstName: z.string().trim().regex(nameRegex, {
    message: 'First name is invalid.',
  }),
  lastName: z.string().trim().regex(nameRegex, {
    message: 'Last name is invalid.',
  }),
  gender: z.string().optional(),
  ageRange: z.string().optional(),
})

interface IInputValues {
  firstName: string
  lastName: string
  'profile.personalInfo.gender'?: string
  'profile.personalInfo.ageRange'?: string
}

interface IProps {
  user: IUser
}

export function PersonalForm({ user }: IProps): JSX.Element {
  const [fieldErrs, setFieldErr] = useState<IFormErrors>(fldErrsDefaults)
  const [inputValues, setInputValues] = useState<IInputValues>({
    firstName: user.firstName,
    lastName: user.lastName,
    'profile.personalInfo.ageRange': user.profile.personalInfo.ageRange ?? '',
    'profile.personalInfo.gender': user.profile.personalInfo.gender ?? '',
  })

  // const loading = false
  const [setUserVals, { loading }] = useMutation(SET_USER_VALS)

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault()

    setFieldErr(fldErrsDefaults)

    const target = e.target as typeof e.target & {
      firstName: { value: string }
      lastName: { value: string }
      'profile.personalInfo.gender': { value: string }
      'profile.personalInfo.ageRange': { value: string }
      reset: () => null
    }

    const result = FormData.safeParse({
      firstName: target.firstName.value,
      lastName: target.lastName.value,
      gender: target['profile.personalInfo.gender'].value,
      ageRange: target['profile.personalInfo.ageRange'].value,
    })

    if (!result.success) {
      const errors = result.error.format() as any
      for (const key in errors) {
        if (key === '_errors') continue
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          setFieldErr((fieldErrs) => ({
            ...fieldErrs,
            [key]: errors[key]._errors.join(', '),
          }))
        }
      }
      return
    }
    if (result.success) {
      const json = JSON.stringify(inputValues, null, 2)
      void setUserVals({ variables: { input: json } })
      toast.success(PROFILE_PERSONAL_INFO_UPDATED)
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ): void => {
    const { name, value } = event.target
    setInputValues({
      ...inputValues,
      [name]: value,
    })
  }

  return (
    <SectionContainer heading='Personal Information'>
      <form onSubmit={handleSubmit}>
        <fieldset className='grid grid-cols-1 lg:grid-cols-2 gap-x-6 px-1 mt-4 gap-y-6'>
          <div className=''>
            <label htmlFor='firstName' className={SecInputLabel}>
              First Name
            </label>
            <input
              id='firstName'
              autoComplete='given-name'
              className={SecInput}
              name='firstName'
              onChange={handleInputChange}
              required
              type='text'
              value={inputValues.firstName}
            />
            {fieldErrs.firstName !== false && <ErrMsg errStr={fieldErrs.firstName} />}
          </div>

          <div className=''>
            <label htmlFor='lastName' className={SecInputLabel}>
              Last Name
            </label>
            <input
              id='lastName'
              autoComplete='family-name'
              className={SecInput}
              name='lastName'
              onChange={handleInputChange}
              type='text'
              value={inputValues.lastName}
            />
            {fieldErrs.lastName !== false && <ErrMsg errStr={fieldErrs.lastName} />}
          </div>

          <div className=''>
            <label htmlFor='gender' className={SecInputLabel}>
              Gender
            </label>
            <select
              id='profile.personalInfo.gender'
              className={SecInput}
              name='profile.personalInfo.gender'
              onChange={handleInputChange}
              value={inputValues['profile.personalInfo.gender']}
            >
              <option />
              <option>Male</option>
              <option>Female</option>
              <option>Other</option>
            </select>
          </div>

          <div className=''>
            <label htmlFor='gender' className={SecInputLabel}>
              Age Range
            </label>
            <select
              id='profile.personalInfo.ageRange'
              className={SecInput}
              name='profile.personalInfo.ageRange'
              onChange={handleInputChange}
              value={inputValues['profile.personalInfo.ageRange']}
            >
              <option />
              <option value='Under18'>Under 18</option>
              <option value='Between18And24'>18-24</option>
              <option value='Between25And34'>25-34</option>
              <option value='Between35And44'>25-44</option>
              <option value='Between45And54'>45-54</option>
              <option value='Between45And54'>55-64</option>
              <option value='Over65'>65+</option>
            </select>
          </div>

          <div className='lg:col-span-2 flex justify-end'>
            <Button className={`${UtilPrimButtonStyle} w-56`} type='submit'>
              {loading ? `Stand by...` : `Save Personal Information`}
            </Button>
          </div>
        </fieldset>
      </form>
    </SectionContainer>
  )
}

export default PersonalForm
