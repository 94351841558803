import { gql } from '@apollo/client'

import { LIST_PRODUCT_FIELDS } from './fragments'

/* export const SET_SHOPLIST = gql`
  mutation SetShopList {
    setShopList {
      code
      success
      list {
        _id
        items {
          quantity
        }
      }
    }
  }
` */

export const SET_SHOPLIST_ITEM = gql`
  ${LIST_PRODUCT_FIELDS}
  mutation SetShopListItem($input: ShopListItemInput!) {
    setShopListItem(input: $input) {
      code
      success
      item {
        ...ListProductFields
      }
    }
  }
`

export const SET_SHOPLIST_ITEM_QTY = gql`
  mutation SetShopListItemQty($input: ShopListItemQtyInput!) {
    setShopListItemQty(input: $input) {
      code
      success
    }
  }
`

export const DELETE_SHOPLIST_ITEM = gql`
  mutation DeleteShopListItem($listId: ID!, $itemId: ID!) {
    deleteShopListItem(listId: $listId, itemId: $itemId) {
      code
      success
    }
  }
`

export const DELETE_SHOPLIST = gql`
  mutation DeleteShopList($listId: ID!) {
    deleteShopList(listId: $listId) {
      code
      success
    }
  }
`

export const PERSIST_SHOPLIST = gql`
  mutation PersistShopList($listId: String!) {
    persistShopList(listId: $listId) {
      code
      success
    }
  }
`

export const ACTIVATE_SHOPLIST = gql`
  mutation ActivateShopList($listId: ID!) {
    activateShopList(listId: $listId) {
      code
      success
      list {
        _id
      }
    }
  }
`
