import { IAddress } from '../modules/user/components/AutocompleteForm'

// TODO: write tests against google maps api response
export function extractAddressFromPlace(place: any): IAddress {
  const address: IAddress = {
    city: '',
    country: '',
    latitude: 0,
    longitude: 0,
    postalCode: '',
    stateProvince: '',
    street: '',
  }

  let streetNumber: string | undefined
  let streetName: string | undefined

  place.forEach((component: any) => {
    const componentType = component.types[0]

    if (componentType === 'street_number') {
      streetNumber = component.long_name
    } else if (componentType === 'route') {
      streetName = component.long_name
    } else if (componentType === 'locality') {
      address.city = component.long_name
    } else if (componentType === 'administrative_area_level_1') {
      address.stateProvince = component.short_name
    } else if (componentType === 'country') {
      address.country = component.short_name
    } else if (componentType === 'postal_code') {
      address.postalCode = component.long_name
    }
  })

  // Combine the street number and street name to form the final address
  if (streetNumber != null && streetName != null) {
    address.street = `${streetNumber} ${streetName}`
  } else if (streetNumber != null) {
    address.street = streetNumber
  } else if (streetName != null) {
    address.street = streetName
  }

  return address
}

export function formatPrice(price: number): string {
  return price.toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
  })
}

// found this at: https://stackoverflow.com/questions/50029024/utc-date-conversion-to-local-date-does-not-work-safari
export function parseUTCDate(date: string): Date {
  const b: string[] = date.split(/\D/)

  return new Date(Date.UTC(+b[0], Number(b[1]) - 1, +b[2], +b[3], +b[4], +b[5]))

  // return new Date(Date.UTC(+b[0], --b[1], +b[2], +b[3], +b[4], +b[5]))
  // return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]))
}
