import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'

import { SecButStyle } from '../../../styles'

interface IModalProps {
  isOpen: boolean
}
export function MissingStoresModal({ isOpen }: IModalProps): JSX.Element {
  const navigate = useNavigate()

  const setIsOpen = (): null => null

  function closeModal(): void {
    setIsOpen()
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title as='h3' className='text-xl font-semibold leading-6 text-gray-900'>
                    Missing Stores
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-base text-gray-500 leading-7'>
                      You have not selected your stores.
                      <br />
                      Please do so to enable searching products.
                    </p>
                  </div>

                  <div className='mt-4'>
                    <button
                      type='button'
                      className={SecButStyle}
                      onClick={() => navigate('/me/store-favs')}
                    >
                      Go to store selector
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default MissingStoresModal
