import { HeaderCentered as Header } from '../../../layout/index'
import { Container } from '../../../auth/components/Common'

export function DeleteAccountConfirmation(): JSX.Element {
  return (
    <div className='bg-white h-screen md:bgimg'>
      <Header />
      <Container>
        <div className='flex flex-col'>
          <div className='text-base font-semibold leading-6 text-slate-900 mb-2'>
            Sorry to see you go!
          </div>
          <div className='text-base text-slate-900'>
            Your account has been deleted.
            <br />
            Thank you for being a Savvie shopper!
          </div>
        </div>
      </Container>
    </div>
  )
}

export default DeleteAccountConfirmation
