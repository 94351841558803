export const UPPERCASE_REGEX = /.*[A-Z]/
export const LOWERCASE_REGEX = /.*[a-z]/
export const NUMBER_REGEX = /.*\d/
export const LENGTH_REGEX = /.{8,}$/
export const SPECIAL_CHARS_REGEX = /.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/

export const PASSWORD_VALID_REGEX = new RegExp(
  `^(?=${[
    LENGTH_REGEX.source,
    UPPERCASE_REGEX.source,
    LOWERCASE_REGEX.source,
    NUMBER_REGEX.source,
    SPECIAL_CHARS_REGEX.source,
  ].join(')(?=')}).*$`,
)
