import { Link } from 'react-router-dom'

import logo from '../../../assets/images/logo.svg'

export function LinkedHeader(): JSX.Element {
  return (
    <header className='flex bg-white items-center h-20 shadow-sm shadow-slate-300'>
      <Link to='/'>
        <img className='relative h-16 pl-4' src={logo} alt='Savvie' />
      </Link>
    </header>
  )
}

export default LinkedHeader
