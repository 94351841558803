import { useState } from 'react'

import { formatPrice } from '../../../utils'
import { IProduct } from '../types'
import { ProductDetail } from './ProductDetail'
import placeHolder from '../../../assets/images/placeholder-image.png'

export interface ICardProps {
  prod: IProduct
}

function Prices({ prod }: ICardProps): JSX.Element {
  let haveSale = false
  if (prod.listPrice > prod.bestPrice) {
    haveSale = true
  }
  return (
    <>
      {haveSale ? (
        <div className=''>
          <span className='font-bold text-lg bg-yellow-200 rounded-lg py-1 px-2'>
            {formatPrice(prod.bestPrice)}
          </span>
          {prod.listPrice > prod.bestPrice && (
            <div className='text-sm line-through text-slate-600 p-1'>
              {formatPrice(prod.listPrice)}
            </div>
          )}
        </div>
      ) : (
        <div className='font-bold text-lg'>{formatPrice(prod.bestPrice)}</div>
      )}
    </>
  )
}

export const PrimButStyle =
  'w-1/2 h-8 rounded-full justify-center items-center flex bg-secondary-600 ring-1 ring-inset ring-secondary-600 text-white hover:bg-secondary-900'

export function StoreResultsCard({ prod }: ICardProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)

  function closeModal(): void {
    setIsOpen(false)
  }

  function openModal(): void {
    setIsOpen(true)
  }

  const prodImg = prod.imageUrl === '' ? placeHolder : prod.imageUrl

  return (
    <div className='ml-3 mb-4 snap-start  scroll-ml-2'>
      <div className='flex flex-col w-[12.6rem] h-[9rem] bg-white rounded-lg border border-white hover:border hover:border-slate-300 hover:shadow-sm hover:shadow-slate-400'>
        <button className='' onClick={openModal}>
          <h3 className='h-9 pt-1 text-sm font-normal leading-4 text-slate-900 overflow-clip'>
            {prod.title}
          </h3>
          <div className='flex flex-row flex-grow p-1'>
            <img className='object-contain h-24 w-24' src={prodImg} alt={prod.title} />
            <div className='flex flex-grow flex-col'>
              <Prices prod={prod} />
              <div className='text-slate-800 text-xs leading-4'>{prod.brand}</div>
              <div className='text-slate-800 text-xs'>{prod.sizeText}</div>
            </div>
          </div>
        </button>
        <ProductDetail isOpen={isOpen} closeModal={closeModal} product={prod} />
      </div>
    </div>
  )
}

export default StoreResultsCard
