import Card from './Card'
import { useQuery } from '@apollo/client'
// import { GET_PRODUCT_DEALS } from '../graphql'
import { GET_PRODUCT_RECOMMENDATIONS } from '../graphql'
import { IProduct } from '../types'
import LoaderModal from '../../common/LoaderModalLt'

export function Sales(): JSX.Element {
  const { loading, data } = useQuery(GET_PRODUCT_RECOMMENDATIONS, {
    // variables: { resultLimit: 10 },
    onError: (error) => {
      console.log(error)
    },
  })

  const products: IProduct[] = data?.getProductRecommendations ?? []

  return (
    <>
      <div className='flex flex-col py-2 pl-6 text-slate-600'>
        <div className='text-slate-800 text-xl font-semibold'>Deals</div>
        <div className=''>Save on items from your stores</div>
      </div>
      <div className='flex flex-wrap min-h-screen px-2'>
        {products.map((p) => (
          <Card key={p._id} prod={p} />
        ))}
      </div>
      <LoaderModal isOpen={loading} message='Stand by while we load your deals' />
    </>
  )
}

export default Sales
