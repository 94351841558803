import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import { LinkedHeader as Header, Footer } from '../../layout/index'
import { TextLinkStyle } from '../../../styles'

export function ErrorPage(): JSX.Element {
  const error = useRouteError()
  console.error(error)
  let errorMessage: string

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data.message || error.statusText
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    console.error(error)
    errorMessage = 'Unknown error'
  }

  return (
    <>
      <Header />
      <div id='error-page' className='h-screen w-1/2 mx-auto'>
        <h1 className='text-3xl font-semibold my-4'>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{errorMessage}</i>
        </p>
        <p className='flex flex-col pt-3 space-y-2 text-lg font-semibold'>
          <a href='/' className={TextLinkStyle}>
            Go Shopping
          </a>
        </p>
      </div>
      <Footer />
    </>
  )
}

export default ErrorPage
