import React, { Fragment, useEffect, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
// import { useQuery } from '@apollo/client'

// import { SEARCH_PRODUCT_RECOMMENDATIONS } from '../'

import { formatPrice } from '../../../utils'
import { IProduct } from '../types'
import { productQtyOptions } from '../utils'
import { QtySelectStyle } from '../../../styles'
import { AddToListbutton } from '../'

interface IProductDetailProps {
  closeModal: () => void
  isOpen: boolean
  product: IProduct
}

export interface ICardProps {
  prod: IProduct
}

function Prices({ prod }: ICardProps): JSX.Element {
  let haveSale = false
  if (prod.listPrice > prod.bestPrice) {
    haveSale = true
  }
  return (
    <>
      {haveSale ? (
        <div className='flex flex-col items-center gap-y-2'>
          <div className='font-bold text-lg bg-yellow-200 rounded-lg py-1 px-2'>
            {formatPrice(prod.bestPrice)}
          </div>
          {prod.listPrice > prod.bestPrice && (
            <div className='text-sm line-through'>{formatPrice(prod.listPrice)}</div>
          )}
        </div>
      ) : (
        <div className='font-bold text-lg'>{formatPrice(prod.bestPrice)}</div>
      )}
    </>
  )
}

export function ProductDetail(props: IProductDetailProps): JSX.Element {
  const { closeModal, isOpen, product: prod } = props
  const [productQtyOption, setProductQtyOption] = useState<number>(1)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setProductQtyOption(Number(event.target.value))
  }
  // console.log('prod', prod)

  /* const { data, loading } = useQuery(SEARCH_PRODUCT_RECOMMENDATIONS, {
    variables: { input: { productId: prod._id } },
  })

  if (data && data.searchProductRecommendations) {
    console.log('data', data)
  } */

  useEffect(() => {
    const close = (e: { key: string }): void => {
      if (e.key === 'Escape') {
        closeModal()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [closeModal])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex mt-20 min-h-max items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all max-w-screen-xl'>
                  <div className='flex flex-col'>
                    <div className='absolute right-2 top-1 inline-flex h-10 items-center'>
                      <button
                        className='p-1.5 text-xs font-semibold text-primary-700 bg-primary-100 rounded-xl hover:bg-primary-200 hover:text-secondary-600'
                        type='button'
                        onClick={closeModal}
                      >
                        <span className='hidden sm:block'>ESC</span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={2}
                          stroke='currentColor'
                          className='block sm:hidden w-5 h-5 stroke-primary-900'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                      </button>
                    </div>

                    <div className='text-xl sm:text-3xl font-semibold text-slate-900 mb-4 text-center'>
                      {prod.title}
                    </div>

                    <div className='flex flex-col lg:flex-row md:gap-x-2 gap-y-4 sm:gap-y-0'>
                      <img
                        className='flex-none object-contain h-64 w-64 sm:h-56 sm:w-56 xl:h-96 xl:w-96 mx-auto'
                        src={prod.imageUrl}
                        alt='{prod.title}'
                      />
                      <div className='flex-col flex-auto'>
                        {prod.description !== '' && (
                          <>
                            <div className='font-semibold text-lg'>Description</div>
                            <div
                              className='leading-relaxed product-description text-sm sm:text-baseline'
                              dangerouslySetInnerHTML={{ __html: prod.description }}
                            ></div>
                          </>
                        )}
                        {prod.ingredients !== '' && (
                          <div className='flex-col'>
                            <div className='font-semibold text-lg pt-2'>Ingredients</div>
                            <p className='p-2 text-sm sm:text-baseline'>{prod.ingredients}</p>
                          </div>
                        )}
                        {prod.category !== '' && (
                          <div className='flex-col'>
                            <div className='font-semibold text-lg pt-2'>Category</div>
                            <p className='p-2'>{prod.category}</p>
                          </div>
                        )}
                      </div>

                      <div className='flex-auto'>
                        <div className='flex-col p-2  max-w-72 lg:p-5 border border-slate-400 rounded-2xl'>
                          <div className='flex flex-row gap-x-2 md:gap-x-4'>
                            <Prices prod={prod} />

                            {prod.sizeText !== '' && (
                              <div className='text-slate-600 mt-1 text-sm whitespace-nowrap'>
                                Size: {` `}
                                {prod.sizeText}
                              </div>
                            )}
                          </div>

                          <div className='flex justify-around sm:gap-x-4 mt-2'>
                            <select
                              id='qty'
                              name='qty'
                              className={QtySelectStyle}
                              onChange={handleSelectChange}
                            >
                              {productQtyOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <AddToListbutton
                              product={prod}
                              qty={productQtyOption}
                              closeModalFn={closeModal}
                            />
                          </div>
                        </div>

                        <div className='text-slate-800 mt-4 sm:ml-2 uppercase'>
                          {prod.store.bannerName} - {prod.store.address}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
