import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../modules/auth/authSlice'
import shopListSlice from '../modules/products/shopListSlice'
import userSlice from '../modules/user/userSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    shopList: shopListSlice,
    user: userSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
