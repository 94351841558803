import { HeaderCentered as Header } from '../../layout/index'
import { Container } from './Common'
// import { Link } from 'react-router-dom'
// import { TextLinkStyle } from '../../../styles/common'

export function RequestPROK(): JSX.Element {
  return (
    <div className='bg-white h-screen md:bgimg'>
      <Header />
      <Container>
        <h1 className='text-2xl'>Password Reset Sent </h1>
        <div className='mt-4'>
          If the account exists an email will be sent with a link to reset your password.
          {/* <br />
          <br />
          <Link className={`${TextLinkStyle} text-xl`} to='/signin'>
            Sign In
          </Link> */}
        </div>
      </Container>
    </div>
  )
}

export default RequestPROK
