import { useEffect, useRef, useState } from 'react'
// import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import Smartlook from 'smartlook-client'

import { GET_SHOPLIST } from '../../products/graphql'
import { GET_USER_SESSION } from '../../auth/graphql'
import { Header, HeaderWithProfile, Footer } from '../../layout'
import { MissingStoresModal } from '../'
import { ScrollToTop } from '../../common'
import { setShopListId } from '../../products/shopListSlice'
import {
  deleteUserSession,
  // RoleBitsSubsActive,
  RoleBitsStageUser,
  setUserSession,
} from '../../auth/authSlice'
import { useAppDispatch, useAppSelector } from '../../../config'
import LoaderModal from '../../common/LoaderModalLt'
// import { ErrorPage } from '../../main'
import BetaNotice from './BetaNotice'
import { isStageMode } from '../../../utils'

export function App(): JSX.Element {
  const [isAuth, setIsAuth] = useState<boolean>(false) // set this to true when we have returned a session from the server
  const [isUserStores, setIsUserStores] = useState<boolean>(true)
  const location = useLocation()
  // const navigate = useNavigate()
  const { term: searchTerm } = useParams()

  const dispatch = useAppDispatch()
  const calledOnce = useRef(false)

  const userSessionId = useAppSelector((state) => state.auth.userSession?.sessionId)
  // const userSession = useAppSelector((state) => state.auth.userSession)

  const [getUserSession, { loading }] = useLazyQuery(GET_USER_SESSION, {
    // TODO: test if 'network-only' is require in order to allow us to fetch the user session from the server every time even after account changes
    // fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // console.log('getUserSession called', data)
      if (data.getUserSession !== null && !isAuth) {
        const { roleBits } = data.getUserSession

        // Check if non-stage user is trying to access stage site
        if (isStageMode() && (roleBits & RoleBitsStageUser) === 0) {
          alert('You are not authorized to access this site.')
          dispatch(deleteUserSession())
          return
        }

        // Check if user has active subscription
        // TODO: This is not currently used and needs refactoring with feature restrictions
        /* if ((roleBits & RoleBitsSubsActive) === 0) {
          navigate('/me/account')
          return
        } */

        // Safe to set isAuth and set the user shoplist
        setIsAuth(true)
        dispatch(setUserSession(data.getUserSession))
        const { haveStoreIds } = data.getUserSession
        setIsUserStores(haveStoreIds)
        void getShopList()

        // console.log('calling smartlook identify in App')
        Smartlook.identify(data.getUserSession.email, {
          email: data.getUserSession.email,
          name: `${data.getUserSession.firstName} ${data.getUserSession.lastName}`,
        })
      } else {
        // TODO: check if this is actually used -- suspect not...
        // console.log('delete user session in Auth')
        setIsAuth(false)
        dispatch(deleteUserSession())
        // console.log('redirect to login')
      }
    },
  })

  const [getShopList] = useLazyQuery(GET_SHOPLIST, {
    onCompleted: (data) => {
      if (data.getShopList !== null) {
        // console.log('calling getShopList in App: ', data.getShopList)
        dispatch(setShopListId(data.getShopList._id))
      }
    },
  })

  // FIXME: clean-up file

  // useEffect(() => {
  //   if (calledOnce.current) return
  //   if (userSession !== null) {
  //     console.log('userSession in App: ', userSession)
  //   }
  // }, [userSession])

  // TODO: check here if we have a userSession in localstorage, then fetch it from redis, if not deleteUserSession()
  useEffect(() => {
    if (calledOnce.current) return
    calledOnce.current = true
    // console.log('calling getUserSession in App: ', userSessionId)
    if (userSessionId !== null) {
      void getUserSession({ variables: { sessionId: userSessionId } })
    }
  }, [getUserSession, userSessionId])

  useEffect(() => {
    if (location.pathname.startsWith('/search') && searchTerm) {
      Smartlook.track('Search', { searchTerm })
      Smartlook.navigation('/search')
    } else {
      Smartlook.navigation(location.pathname)
    }
  }, [location, searchTerm])

  if (import.meta.env.DEV) {
    // Adds messages only in a dev environment
    loadDevMessages()
    loadErrorMessages()
  }

  const header = location.pathname === '/shopping-list' ? <HeaderWithProfile /> : <Header />

  return (
    <>
      <div className='bg-white'>
        {loading ? (
          <LoaderModal isOpen={loading} />
        ) : (
          <>
            <ScrollToTop />
            {header}
            <Outlet />
            <Footer />
          </>
        )}
      </div>
      <BetaNotice />
      <MissingStoresModal isOpen={!isUserStores} />
    </>
  )
}

export default App
