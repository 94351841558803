import { Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { useAppSelector, useAppDispatch } from '../../../config'
import { UtilPrimButtonStyle, UtilSecButtonStyle } from '../../../styles'
import { GET_SHOPLISTS_ARCHIVE, PERSIST_SHOPLIST } from '../graphql'
import { deleteShopListId } from '../shopListSlice'

interface IModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export function SaveListModal({ isOpen, setIsOpen }: IModalProps): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const shopListId = useAppSelector((state) => state.shopList.listId)

  const closeModal = (): void => {
    setIsOpen(false)
  }

  const [persistShopList, { loading }] = useMutation(PERSIST_SHOPLIST, {
    variables: { listId: shopListId },
    onCompleted: () => {
      // if we got here with a null shopListId, something is wrong
      const listId = shopListId as string
      dispatch(deleteShopListId())
      setIsOpen(false)
      navigate(`/me/archived-list/${listId}`)
    },
    refetchQueries: [GET_SHOPLISTS_ARCHIVE],
  })

  const handleConfirmSave = (): void => {
    void persistShopList()
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title as='h3' className='text-xl font-medium leading-6 text-slate-800'>
                    Finished Shopping?
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>Save your shopping list</p>
                  </div>

                  <div className='flex flex-row justify-center space-x-4 mt-4'>
                    <button
                      type='button'
                      className={`${UtilPrimButtonStyle} w-36`}
                      onClick={handleConfirmSave}
                      disabled={loading}
                    >
                      {loading ? `Stand by...` : `Confirm Save`}
                    </button>
                    <button
                      type='button'
                      className={UtilSecButtonStyle}
                      onClick={closeModal}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default SaveListModal
