import { gql } from '@apollo/client'

export const GET_USER = gql`
  query GetUser {
    getUser {
      _id
      email
      firstName
      lastName
      preferences {
        storeIds
      }
      profile {
        address {
          city
          country
          postalCode
          stateProvince
          street
        }
        personalInfo {
          ageRange
          gender
        }
      }
      __typename
    }
  }
`
// FIXME: clean up this query. Stripe config has changed and so some refactoring is needed
export const GET_USER_ACCOUNT_INFO = gql`
  query GetUserAccountInfo {
    getUser {
      _id
      email
      firstName
      lastName
      sessionId
      subscription {
        active
        planName
        status
        # trial {
        # endTS
        # }
        # stripe {
        # couponId
        # customerId
        #     subscription {
        #       id
        #       status
        #     }
        # }
        updateTS
      }
    }
    stripeConfig {
      publishableKey
    }
    # stripeGetSubscription {
    #   id
    #   status
    #   subscription {
    #     currency
    #     daysUntilDue
    #     defaultPaymentMethod
    #     discount
    #     trialEnd
    #     trialStart
    #   }
    # }
  }
`

export const GET_USER_STORES = gql`
  query GetUserStores {
    getUserStores {
      _id
      address
      bannerName
      chain
      city
      postalCode
      state
    }
  }
`

export const SEARCH_STORES = gql`
  query SearchStores($input: SearchStoresInput!) {
    searchStores(input: $input) {
      _id
      address
      bannerName
      chain
      city
      distance {
        calculated
        location {
          coordinates
        }
      }
      latitude
      longitude
      postalCode
      state
    }
  }
`

export const STRIPE_GET_PORTAL_SESSION = gql`
  query StripeGetPortalSession {
    stripeGetPortalSession {
      url
    }
  }
`

export const STRIPE_GET_PORTAL_SESSION_FOR_PAYMENT_METHOD = gql`
  query StripeGetPortalSessionForPaymentMethod {
    stripeGetPortalSessionForPaymentMethod {
      url
    }
  }
`

export const STRIPE_GET_PUBLISHABLE_KEY = gql`
  query StripeConfig {
    stripeConfig {
      publishableKey
    }
  }
`
