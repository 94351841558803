import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'

import { DELETE_SHOPLIST_ITEM, GET_SORTED_SHOPLIST, SET_SHOPLIST_ITEM_QTY } from '../graphql'
import { formatPrice } from '../../../utils'
import { productQtyOptions } from '../utils'
import {
  QtySelectStyle,
  TextLinkStyle,
  UtilPrimButtonStyle,
  UtilSecButtonStyle,
} from '../../../styles'
import { useAppSelector } from '../../../config'
import LoaderModal from '../../common/LoaderModalLt'
import { SaveListModal } from './SaveListModal'
import { IShopList } from '../types'
import placeHolder from '../../../assets/images/placeholder-image.png'

export function ShoppingList(): JSX.Element {
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false)
  const [shopList, setShopList] = useState<IShopList>({
    _id: '',
    createTS: '',
    stores: [],
    totalCost: 0,
    updateTS: '',
  })

  const shopListId = useAppSelector((state) => state.shopList.listId)

  const [fetchList, { data, loading }] = useLazyQuery(GET_SORTED_SHOPLIST)

  const [deleteShopListItem] = useMutation(DELETE_SHOPLIST_ITEM, {
    refetchQueries: [GET_SORTED_SHOPLIST],
  })

  const handleDeleteItem = async (itemId: string): Promise<void> => {
    const newStores = shopList.stores.map((store) => {
      const newItems = store.items.filter((item) => item._id !== itemId)
      return {
        ...store,
        items: newItems,
        storeTotal: newItems.reduce((acc, item) => acc + item.subTotal, 0),
      }
    })
    const newStoresFiltered = newStores.filter((store) => store.items.length > 0)
    const newTotalCost = newStoresFiltered.reduce((acc, store) => acc + store.storeTotal, 0)
    setShopList({ ...shopList, totalCost: newTotalCost, stores: newStoresFiltered })
    window.scrollTo(0, 0)

    await deleteShopListItem({
      variables: { listId: shopListId, itemId },
    })
  }

  const [setItemQty] = useMutation(SET_SHOPLIST_ITEM_QTY, {
    /* optimisticResponse: (data) => {
      const { productId, quantity } = data.input
      const newStores = shopList.stores.map((store) => {
        const newItems = store.items.map((item) => {
          if (item.productId === productId) {
            return { ...item, quantity, subTotal: item.unitPrice * quantity }
          }
          return item
        })
        store.items = newItems
        return store
      })
      setShopList({ ...shopList, stores: newStores })
      toast.success(ITEM_UPDATED)
    }, */
    refetchQueries: [GET_SORTED_SHOPLIST],
  })

  const handleSelectChangeQty = async (quantity: number, itemId: string): Promise<void> => {
    const newStores = shopList.stores.map((store) => {
      const newItems = store.items.map((item) => {
        if (item._id === itemId) {
          return { ...item, quantity, subTotal: item.unitPrice * quantity }
        }
        return item
      })
      return {
        ...store,
        items: newItems,
        storeTotal: newItems.reduce((acc, item) => acc + item.subTotal, 0),
      }
    })
    const newTotalCost = newStores.reduce((acc, store) => acc + store.storeTotal, 0)
    setShopList({ ...shopList, totalCost: newTotalCost, stores: newStores })
    window.scrollTo(0, 0)

    await setItemQty({
      variables: {
        input: {
          listId: shopListId,
          itemId,
          quantity,
        },
      },
    })
  }

  useEffect(() => {
    if (shopListId === null) return
    void fetchList({
      variables: {
        listId: shopListId,
      },
    })
  }, [fetchList, shopListId])

  useEffect(() => {
    if (data !== undefined) {
      setShopList(data.getSortedShopList)
    }
  }, [data])

  if ((data === undefined && loading) || shopListId === null)
    return (
      <>
        <LoaderModal isOpen={true} />
        <div className='flex h-screen bg-primary-200'></div>
      </>
    )

  const haveShopList = shopList.stores.length

  return (
    <>
      <div className='flex flex-col md:flex-row-reverse xl:gap-x-1 justify-center xl:pt-4 mx-auto max-w-7xl px-3 sm:px-0 min-h-screen'>
        {/* Summary */}
        <div className='flex flex-col lg:w-3/12 min-w-56 mb-2 sm:mb-0 mt-0 sm:mt-1 pb-3  border-b sm:border-none border-b-slate-200'>
          {haveShopList && (
            <div className='px-3 pt-1 sm:px-6 sm:pt-3'>
              <div className='text-2xl sm:text-3xl font-medium text-slate-900 mb-2'>Summary</div>

              <div className='divide-y divide-slate-200'>
                {shopList.stores.map((store: any) => (
                  <div key={store.storeId} className='flex flex-row items-center h-10 gap-x-2'>
                    <div className='flex flex-grow text-sm'>{store.storeName}</div>
                    <div className=' text-sm'>{formatPrice(store.storeTotal)}</div>
                  </div>
                ))}
                <div className='flex flex-row items-center h-11 font-bold'>
                  <div className='flex flex-grow'>Total</div>
                  <div className=''>{formatPrice(shopList.totalCost)}</div>
                </div>
              </div>

              <div className='inline-flex w-full md:flex-col justify-around md:justify-center md:items-center sm:mx-0 gap-x-4 sm:gap-x-0 md:space-y-3 mt-2'>
                <button
                  className={`${UtilPrimButtonStyle} w-44`}
                  onClick={() => setIsOpenConfirm(!isOpenConfirm)}
                  type='button'
                >
                  Save List
                </button>
                <Link to='/' className={`${UtilSecButtonStyle} min-w-44`}>
                  Continue Shopping
                </Link>
              </div>
            </div>
          )}

          {/* <div className='flex flex-col mt-6 px-6 bg-white rounded-lg border-2 border-teal-500'>
          <div className='font-semibold text-lg py-2 b-6 text-slate-800'>Recommendations</div>
          <div className='space-y-6'>
            {recProds.map((prod) => (
              <div key={prod.id} className=''>
                <div className='font-semibold mb-2 truncate text-ellipsis text-slate-700'>
                  {prod.title}
                </div>
                <div className='flex flex-row'>
                  <img
                    className='object-contain h-24 w-24'
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    src={`${mockImageDir}${prod.image}`}
                  />
                  <div className='flex flex-col ml-4'>
                    <div className='font-bold text-red-700'>
                      ${prod.price0.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </div>
                    <button className='mt-2 px-2 py-1 text-xs border rounded-md border-slate-300 bg-slate-50 hover:bg-slate-100'>
                      Add to List
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        </div>

        <div className='flex flex-col lg:w-9/12 sm:pt-4'>
          <div className='flex items-baseline mx-2 sm:mx-6 mb-4 pb-1'>
            <div className='flex flex-grow text-2xl sm:text-3xl font-medium text-slate-900'>
              Shopping List
            </div>
            {haveShopList && <div className='flex'>Price</div>}
          </div>

          <div className='flex flex-col px-2 sm:px-6 pb-6'>
            {!haveShopList && !loading && (
              <div className=''>
                <p>Your shopping list is currently empty.</p>
                <p>
                  Please search for products using the search feature, or check out the{' '}
                  <Link to='/' className={TextLinkStyle}>
                    <b>savings in your area.</b>
                  </Link>
                </p>
              </div>
            )}

            {shopList.stores.map((store: any) => (
              <div key={store.storeId} className='flex pt-8 first:pt-0'>
                <table className='w-full border-collapse'>
                  <thead>
                    <tr className=''>
                      <th
                        colSpan={3}
                        className='pb-2 pt-4 first:pt-0 text-left text-baseline sm:text-xl font-medium'
                      >
                        {store.storeName}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {store.items.map((item: any) => (
                      <tr key={item._id} className='border-b border-slate-200'>
                        <td className='w-20 sm:w-48 sm:pl-2 sm:py-2'>
                          <img
                            className='object-contain h-20 w-20 sm:h-40 sm:w-40'
                            src={item.product?.imageUrl ? item.product.imageUrl : placeHolder}
                            alt={item.title}
                          />
                        </td>
                        <td className='align-text-top pl-3 sm:pl-6 py-2'>
                          <div className='font-semibold text-sm sm:text-lg'>{item.title}</div>
                          <div className='mt-3 ga space-y-1 sm:space-y-3'>
                            <div className='flex flex-row'>
                              <div className='text-sm sm:text-baseline'>
                                {formatPrice(item.unitPrice)}
                              </div>
                              <div className='ml-6 text-sm sm:text-baseline'>{item.sizeText}</div>
                            </div>
                            <div className='flex items-center space-x-3 sm:space-x-6'>
                              <select
                                id='qty'
                                name='qty'
                                className={QtySelectStyle}
                                onChange={(e) =>
                                  handleSelectChangeQty(Number(e.target.value), item.product._id)
                                }
                                value={item.quantity}
                              >
                                {productQtyOptions.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <button
                                className={TextLinkStyle}
                                onClick={() => handleDeleteItem(item._id)}
                              >
                                Delete
                              </button>
                            </div>
                            {!item.product?.imageUrl && (
                              <div className=''>Note: This item may no longer be available</div>
                            )}
                          </div>
                        </td>
                        <td className='w-14 font-semibold text-baseline sm:text-lg align-text-top text-right py-2'>
                          {formatPrice(item.subTotal)}
                        </td>
                      </tr>
                    ))}
                    <tr className='border-b border-slate-600'>
                      <td
                        colSpan={3}
                        className='py-2 text-right text-base sm:text-xl font-semibold'
                      >
                        <span className='text-base font-normal pr-4'>Store Total</span>
                        {formatPrice(store.storeTotal)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
            {haveShopList && (
              <div className='mt-3 text-right text-base sm:text-xl font-semibold'>
                <span className='text-base sm:text-xl pr-4'>List Total</span>
                {formatPrice(shopList.totalCost)}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <LoaderModal isOpen={loading} /> */}
      <SaveListModal isOpen={isOpenConfirm} setIsOpen={setIsOpenConfirm} />
    </>
  )
}

export default ShoppingList
