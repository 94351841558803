import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { useAppDispatch, useAppSelector } from '../../../config'

import { deleteUserSession } from '../../auth/authSlice'

export function ProfileMenu(): JSX.Element {
  const dispatch = useAppDispatch()

  const signOut = (): void => {
    dispatch(deleteUserSession())
  }

  const session = useAppSelector((state) => state.auth.userSession)
  let userName: string | null = ''
  if (session !== null) {
    userName = session.firstName + ' ' + session.lastName
  }

  return (
    <Menu as='div' className='flex'>
      <Menu.Button className=''>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1}
          stroke='currentColor'
          className='w-11 h-11 sm:w-12 sm:h-12 stroke-primary-600 stroke-1 inline-flex'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
          />
        </svg>
      </Menu.Button>
      <Menu.Items className=''>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1'>
              <div className='pl-2 text-slate-600'>{userName}</div>
              <div className='border border-slate-200' />

              <Menu.Item>
                {({ active }) => (
                  <Link
                    to='me/profile'
                    className={clsx(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Profile
                  </Link>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <Link
                    to='me/saved-lists'
                    className={clsx(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Saved Lists
                  </Link>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <Link
                    to='me/store-favs'
                    className={clsx(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    My Stores
                  </Link>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <Link
                    to='me/account'
                    className={clsx(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Account
                  </Link>
                )}
              </Menu.Item>

              {/* <Menu.Item>
            {({ active }) => (
              <Link
                to='me/preferences'
                className={clsx(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                )}
              >
                Preferences
              </Link>
            )}
          </Menu.Item> */}

              <div className='border border-slate-200' />

              <form method='POST' action='#' className=''>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type='submit'
                      className={clsx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm',
                      )}
                      onClick={() => signOut()}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </form>
            </div>
          </Menu.Items>
        </Transition>
      </Menu.Items>
    </Menu>
  )
}

export default ProfileMenu
