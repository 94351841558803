import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import ls from 'localstorage-slim'

import { GRAPHQL_ENDPOINT, USER_SESSION_ID_KEY, USER_SESSION_KEY } from './config'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors != null)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.includes('Unauthorized')) {
        ls.remove(USER_SESSION_ID_KEY)
        ls.remove(USER_SESSION_KEY)
        window.location.reload()
      }
      // TODO: need to display a toast here with a generic error message, as well as log the error to sentry
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      // alert(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  if (networkError != null) {
    console.error(`[Network error]: ${networkError}`)
  }
})

const httpLink = new HttpLink({ uri: GRAPHQL_ENDPOINT })

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = ls.get(USER_SESSION_ID_KEY) as string
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }))

  return forward(operation)
})

const cache = new InMemoryCache({
  typePolicies: {
    // ShopListItem: {
    // FIXME: rename the productId field in the API to _id
    // keyFields: ['product', ['_id']],
    // },
    // ShopList: {
    //   fields: {
    //     items: {
    //       merge(existing, incoming) {
    //         // merge(_, incoming) {
    //         console.log('incoming: ', incoming)
    //         console.log('existing: ', existing)
    //         return incoming
    //       },
    //     },
    //   },
    // },
    Query: {
      fields: {
        // getShopList: {
        //   merge(existing, incoming) {
        //     console.log('incoming: ', incoming)
        //     console.log('existing: ', existing)
        //     return incoming
        //   },
        // },
      },
    },
  },
})

// for more information on Apollo Client devtools, see: https://www.apollographql.com/docs/react/development-testing/developer-tooling/#apollo-client-devtools
// for info in cache policies see: https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
export const client = new ApolloClient({
  // cache: new InMemoryCache(),
  cache,
  link: from([authMiddleware, errorLink, httpLink]),
  name: 'web',
  version: '1.0',
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      // errorPolicy: 'ignore',
    },
    // query: {
    //   fetchPolicy: 'network-only',
    //   errorPolicy: 'all',
    // },
    // mutate: {
    //   errorPolicy: 'all',
    // },
  },
})

export default client
