import { gql } from '@apollo/client'

export const SET_ADDRESS = gql`
  mutation SetAddress($address: AddressInput!) {
    setAddress(address: $address) {
      address {
        city
        country
        postalCode
        stateProvince
        street
      }
      code
      success
    }
  }
`

export const DELETE_STORE = gql`
  mutation DeleteStoreID($storeId: String!) {
    deleteStoreID(storeId: $storeId) {
      code
      success
      storeIds
    }
  }
`

export const DELETE_STORES = gql`
  mutation DeleteStoreIDs {
    deleteStoreIDs {
      code
      success
      storeIds
    }
  }
`

export const SET_STORE = gql`
  mutation SetStoreID($storeId: String!) {
    setStoreID(storeId: $storeId) {
      code
      message
      success
      storeIds
    }
  }
`

export const SET_USER_VALS = gql`
  mutation SetUserVals($input: String!) {
    setUserVals(input: $input) {
      code
      success
      user {
        _id
        email
        firstName
        lastName
        profile {
          personalInfo {
            ageRange
            gender
          }
        }
      }
    }
  }
`

export const RESET_USER_EMAIL = gql`
  mutation ResetEmailEmail($email: String!) {
    resetUserEmail(email: $email) {
      code
      success
      user {
        _id
        email
      }
    }
  }
`

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      code
      success
      user {
        _id
        email
        sessionId
      }
    }
  }
`

export const STRIPE_UPDATE_SUBSCRIPTION_WITH_COUPON = gql`
  mutation StripeUpdateSubscriptionWithCoupon($input: InputStripeUpdateSubscriptionWithCoupon!) {
    stripeUpdateSubscriptionWithCoupon(input: $input) {
      code
      message
      success
      subscription {
        id
        status
      }
    }
  }
`

export const DELETE_USER_ACCOUNT = gql`
  mutation DeleteUserAccount {
    deleteUserAccount {
      code
      success
      message
    }
  }
`
