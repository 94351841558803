export const SecButStyle =
  'h-9 px-3 rounded-full bg-white ring-1 ring-inset ring-primary-600 text-secondary-900 hover:bg-primary-100'

export const BuyButStyle =
  'h-7 rounded-full inline-flex items-center gap-x-.5 pr-1 pl-2.5 bg-secondary-600 ring-1 ring-inset ring-secondary-600 text-white hover:bg-secondary-900'

export const TextLinkStyle =
  'text-primary-800 duration-100 hover:text-primary-900 hover:underline hover:decoration-dashed hover:decoration-primary-600'

export const SecondaryLinkStyle =
  'underline decoration-dotted decoration-slate-600 duration-100 hover:text-primary-600 hover:decoration-primary-600'

export const SecondaryLinkStyle2 = 'text-primary-700 hover:text-secondary-600'

export const PrimButStyle =
  'inline-flex h-8 px-3 rounded-full justify-center items-center bg-primary-600 ring-1 ring-inset ring-primary-600 text-white text-sm whitespace-nowrap hover:bg-primary-900'

//
// ------------------------------------ Form ------------------------------------------------------
//

export const InputStyle =
  'block w-full rounded-md sm:text-sm border-slate-400 text-slate-900 shadow-sm shadow-slate-400 focus:border-primary-600 focus:ring-primary-600'

export const InputNameStyle =
  'h-9 rounded-md text-sm border-slate-400 text-slate-900 shadow-sm shadow-slate-400 focus:border-primary-600 focus:ring-primary-600'

export const SubmitButStyle =
  'flex h-9 px-3 rounded-full justify-center items-center border border-transparent bg-primary-600 font-md tracking-wider text-white drop-shadow-md hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-primary-800 focus:ring-offset-2 transition duration-150 ease-in-out'

export const QtySelectStyle =
  'h-8 w-18 text-xs rounded-full border-slate-300 text-slate-900 shadow-sm bg-slate-50 focus:bg-primary-200 focus:border-primary-500 focus:ring-primary-500'

// TODO: rename these 2 to better reflect their purpose
export const UtilPrimButtonStyle =
  'inline-flex h-8 px-3 rounded-full justify-center items-center bg-primary-600 ring-1 ring-inset ring-primary-600 text-white hover:bg-primary-800 hover:ring-primary-900'

export const UtilSecButtonStyle =
  'inline-flex h-8 px-3 rounded-full justify-center items-center bg-primary-100 ring-1 ring-inset ring-primary-600 text-primary-800 hover:bg-white hover:text-primary-900 hover:ring-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2'

//
// ------------------------------------ Form Admin ------------------------------------------------
//

export const LabelStyle = 'block text-sm font-medium text-slate-500 mb-1'

export const SelectStyle =
  'w-full h-9 text-sm rounded-md border-slate-400 text-slate-900 shadow-sm shadow-slate-400 focus:bg-primary-200 focus:border-primary-500 focus:ring-primary-500'

//
// ------------------------------------ Secondary Styling -----------------------------------------
//

export const SecInputLabel = 'block mb-1 text-sm font-medium text-slate-700'
export const SecInput =
  'block w-full rounded-md border-slate-400 text-slate-900 shadow-sm shadow-slate-400 focus:border-primary-600 focus:ring-primary-600 sm:text-sm'

//
// ------------------------------------ Error Message ---------------------------------------------
//

export const ErrorMsgText = 'text-sm text-slate-600 leading-6'

//
// ------------------------------------ Signup Styling --------------------------------------------
//

export const SignupButtonStyle =
  'flex w-full h-10 px-3 rounded-md justify-center items-center border border-slate-500 bg-white pl-5 drop-shadow-md hover:bg-slate-200 hover:border-slate-900 hover:text-slate-900 transition duration-150 ease-in-out'

export const SignupButtonIconStyle = 'w-5 h-5 mr-2'

export const SignupButtonTextStyle = 'flex-grow text-left font-semibold text-black  tracking-wider'
