import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { useLazyQuery, useMutation } from '@apollo/client'
import { z } from 'zod'
import clsx from 'clsx'
import isStrongPassword from 'validator/lib/isStrongPassword'
import toast from 'react-hot-toast'
import { useAppDispatch } from '../../../../config'

import { Button, InputErrMsg as ErrMsg, SectionContainer } from '../shared'
import { PROFILE_PASSWORD_UPDATED, PROFILE_SUBMISSION_FAILED } from '../../../../config/language'
import { RESET_USER_PASSWORD } from '../../graphql'
import { GET_USER_SESSION } from '../../../auth/graphql'
import { InputStyle, SecInputLabel, UtilPrimButtonStyle, TextLinkStyle } from '../../../../styles'
import { setUserSession } from '../../../auth/authSlice'
import { InfoPopover } from './InfoPopover'
import { PasswordTip } from './PasswordTip'

interface IFormErrors {
  oldPassword: string | false
  newPassword: string | false
  confirmPassword: string | false
}

const fldErrsDefaults: IFormErrors = {
  oldPassword: false,
  newPassword: false,
  confirmPassword: false,
}

const FormData = z
  .object({
    newPassword: z
      .string()
      .trim()
      .refine((val) => isStrongPassword(val), {
        message: 'Invalid password',
      }),
    confirmPassword: z.string().trim(),
  })
  .refine((data) => data.confirmPassword === data.newPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  })

export function EditPasswd(): JSX.Element {
  const [showPasswd, setShowPasswd] = useState<boolean>(false)
  const [fieldErrs, setFieldErr] = useState<IFormErrors>(fldErrsDefaults)

  const dispatch = useAppDispatch()

  const [getUserSession, { loading: userSessLoading }] = useLazyQuery(GET_USER_SESSION, {
    onCompleted: (data) => {
      if (data.getUserSession !== null) {
        dispatch(setUserSession(data.getUserSession))
        toast.success(PROFILE_PASSWORD_UPDATED)
      }
    },
  })

  const [resetUserPassword, { loading }] = useMutation(RESET_USER_PASSWORD, {
    onError: (err) => {
      if (err.message === 'invalid original password') {
        setFieldErr((fieldErrs) => ({
          ...fieldErrs,
          oldPassword: 'Invalid current password',
        }))
        return
      }
      toast.error(PROFILE_SUBMISSION_FAILED)
    },
    onCompleted: (data) => {
      void getUserSession({ variables: { sessionId: data.resetUserPassword.user.sessionId } })
    },
  })

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault()

    setFieldErr(fldErrsDefaults)

    const target = e.target as typeof e.target & {
      oldPassword: { value: string }
      newPassword: { value: string }
      confirmPassword: { value: string }
      reset: () => null
    }

    const result = FormData.safeParse({
      oldPassword: target.oldPassword.value,
      newPassword: target.newPassword.value,
      confirmPassword: target.confirmPassword.value,
    })

    if (!result.success) {
      const errors = result.error.format() as any
      for (const key in errors) {
        if (key === '_errors') continue
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          setFieldErr((fieldErrs) => ({
            ...fieldErrs,
            [key]: errors[key]._errors.join(', '),
          }))
        }
      }
      return
    }

    void resetUserPassword({
      variables: {
        input: {
          oldPassword: target.oldPassword.value,
          newPassword: target.newPassword.value,
        },
      },
    }).then(() => {
      target.reset()
    })
  }

  return (
    <SectionContainer heading='Change Password'>
      <form onSubmit={handleSubmit}>
        <fieldset className='grid grid-cols-1 gap-y-6 mt-6 max-w-sm'>
          <div className='col-span-1'>
            <label htmlFor='oldPassword' className={SecInputLabel}>
              Current Password
            </label>

            {/* oldPassword field */}
            <div className='flex flex-grow'>
              <input
                id='oldPassword'
                name='oldPassword'
                type={showPasswd ? 'text' : 'password'}
                autoFocus
                tabIndex={0}
                className={clsx(
                  'block w-full rounded-none rounded-l-md text-sm border-slate-400 text-slate-900 shadow-sm shadow-slate-400 focus:border-primary-600 focus:ring-primary-600',
                  fieldErrs.oldPassword !== false && 'border-red-600',
                )}
                required
              />
              <button
                type='button'
                onClick={() => setShowPasswd(!showPasswd)}
                className={clsx(
                  'relative -ml-px inline-flex items-center rounded-r-md border border-slate-400 bg-slate-100 px-2.5 py-1 text-sm font-medium text-slate-900 shadow-sm shadow-slate-400 hover:bg-slate-200 transition duration-150 ease-in-out',
                  fieldErrs.oldPassword !== false && 'border-red-600',
                )}
              >
                {showPasswd ? (
                  <EyeSlashIcon className='h-5 w-5 text-primary-600' aria-hidden='true' />
                ) : (
                  <EyeIcon className='h-5 w-5 text-slate-500' aria-hidden='true' />
                )}
              </button>
            </div>

            {fieldErrs.oldPassword !== false && <ErrMsg errStr={fieldErrs.oldPassword} />}
          </div>

          {/* newPassword field */}
          <div className='col-span-1'>
            <div className='flex flex-row items-center'>
              <label htmlFor='newPassword' className={SecInputLabel}>
                New Password
              </label>
              <div className='relative w-0 -right-1'>
                <InfoPopover>
                  <PasswordTip />
                </InfoPopover>
              </div>
            </div>
            <div className='flex flex-grow'>
              <input
                id='newPassword'
                name='newPassword'
                type={showPasswd ? 'text' : 'password'}
                tabIndex={0}
                className={clsx(InputStyle, fieldErrs.newPassword !== false && 'border-red-600')}
                required
              />
            </div>
            {fieldErrs.newPassword !== false && <ErrMsg errStr={fieldErrs.newPassword} />}
          </div>

          {/* confirmPassword field */}
          <div className='col-span-1'>
            <label htmlFor='confirmPassword' className={SecInputLabel}>
              Confirm New Password
            </label>
            <div className='flex flex-grow'>
              <input
                id='confirmPassword'
                name='confirmPassword'
                type={showPasswd ? 'text' : 'password'}
                tabIndex={0}
                className={clsx(
                  InputStyle,
                  fieldErrs.confirmPassword !== false && 'border-red-600',
                )}
                required
              />
            </div>
            {fieldErrs.confirmPassword !== false && <ErrMsg errStr={fieldErrs.confirmPassword} />}
          </div>

          <div className='col-span-1 flex flex-row justify-between items-center'>
            <Link to='/me/profile' className={TextLinkStyle}>
              &larr; Back to Profile
            </Link>
            <Button
              className={UtilPrimButtonStyle}
              type='submit'
              disabled={loading || userSessLoading}
            >
              {loading || userSessLoading ? `Stand by...` : `Save Password`}
            </Button>
          </div>
        </fieldset>
      </form>
    </SectionContainer>
  )
}

export default EditPasswd
