import { Link } from 'react-router-dom'

import { SectionContainer } from '../shared'
import { TextLinkStyle } from '../../../../styles'

interface IProps {
  email: string
}

export function LoginDetails({ email }: IProps): JSX.Element {
  return (
    <SectionContainer heading='Login Details'>
      <dl className='divide-y divide-slate-200'>
        <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5'>
          <dt className='text-sm font-medium text-slate-700'>Email</dt>
          <dd className='mt-1 flex text-sm text-slate-700 sm:col-span-2 sm:mt-0'>
            <span className='flex-grow'>{email}</span>
            <span className='ml-4 flex-shrink-0'>
              <Link to='/me/edit-email' className={`${TextLinkStyle} text-base font-semibold`}>
                Edit
              </Link>
            </span>
          </dd>
        </div>

        <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5'>
          <dt className='text-sm font-medium text-slate-700'>Password</dt>
          <dd className='mt-1 flex text-sm text-slate-700 sm:col-span-2 sm:mt-0'>
            <span className='flex-grow'>********</span>
            <span className='ml-4 flex-shrink-0'>
              <Link to='/me/edit-passwd' className={`${TextLinkStyle} text-base font-semibold`}>
                Edit
              </Link>
            </span>
          </dd>
        </div>
      </dl>
    </SectionContainer>
  )
}
