import {
  UPPERCASE_REGEX,
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  LENGTH_REGEX,
  SPECIAL_CHARS_REGEX,
} from '../../../utils'

// found this at: https://medium.com/@alessio.carnevale/form-validation-submission-in-vanilla-react-part-2-eb7977d81675

interface Props {
  value: string | undefined
  className?: string
}

const rules = [
  { label: 'One uppercase', pattern: UPPERCASE_REGEX },
  { label: 'One lowercase', pattern: LOWERCASE_REGEX },
  { label: 'One number', pattern: NUMBER_REGEX },
  { label: 'One special character', pattern: SPECIAL_CHARS_REGEX },
  { label: 'Min 8 characters', pattern: LENGTH_REGEX },
]

export const PasswdChecklist = (props: Props): JSX.Element => {
  return (
    <div className='text-slate-700 text-xs m-2 -mb-4'>
      {rules.map((rule, i) => {
        const cn =
          props.value?.match(rule.pattern) != null
            ? `text-green-600 before:content-['✓'] gap-x-1 flex`
            : ''
        return (
          <p key={i} className={cn}>
            {rule.label}
          </p>
        )
      })}
    </div>
  )
}

export default PasswdChecklist
