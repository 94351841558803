export function PasswordTip(): JSX.Element {
  return (
    <span className='text-xs font-medium text-black text-left lg:w-56 w-28'>
      <div className='font-semibold pb-1.5'>Passwords must include the following:</div>
      <div className='pb-1.5'>At least 8 characters in length</div>
      <div className='pb-1.5'>Contain at least 1 each of the following 4 types of characters:</div>
      <ul className='leading-5 pl-4 list-disc'>
        <li className=''>Uppercase letters (A-Z)</li>
        <li className=''>Lowercase letters (a-z)</li>
        <li className=''>Numbers (i.e. 0-9)</li>
        <li className=''>Special characters (e.g. !@#$%^&amp;*)</li>
      </ul>
    </span>
  )
}

export default PasswordTip
