import { useEffect } from 'react'

import { useQuery } from '@apollo/client'

import { Address } from './profile/Address'
import { GET_USER } from '../graphql/queries'
import { LoginDetails } from './profile/LoginDetails'
import { PersonalForm } from './profile/PersonalForm'
import { setLoading } from '../userSlice'
import { useAppDispatch } from '../../../config'

export interface IUserAddress {
  city: string
  country: string
  postalCode: string
  stateProvince: string
  street: string
}

export interface IUserPersonalInfo {
  gender?: string
  ageRange?: string
}

export interface IUserProfile {
  address: IUserAddress
  personalInfo: IUserPersonalInfo
}

export interface IUser {
  _id: string
  firstName: string
  lastName: string
  email: string
  profile: IUserProfile
}

export function Profile(): JSX.Element {
  const dispatch = useAppDispatch()
  const { data, loading } = useQuery(GET_USER)

  const user = data?.getUser as IUser | null

  useEffect(() => {
    dispatch(setLoading(loading))
  }, [loading, dispatch])

  return (
    <>
      <div className='flex-1 xl:overflow-y-auto px-4 sm:px-0 space-y-8 divide-y-2 divide-slate-300'>
        {user != null && (
          <>
            <PersonalForm user={user} />
            <LoginDetails email={user.email} />
            <Address address={user.profile.address} />
          </>
        )}
      </div>
    </>
  )
}

export default Profile
