import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import clsx from 'clsx'

import { BasketSlide, CartButton, SearchSlide } from '../../products'
import { formatPrice } from '../../../utils'
import { GET_SHOPLIST } from '../../products/graphql'
import logo from '../../../assets/images/logo.svg'
import Search from './Search'
import { ProfileMenu } from '../'

export function Header(): JSX.Element {
  const client = useApolloClient()
  const [openBasket, setOpenBasket] = useState<boolean>(false)
  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const [totalCost, setTotalCost] = useState<string | null>(null)
  const [numItems, setNumItems] = useState<number>(0)

  const data = client.readQuery({
    query: GET_SHOPLIST,
  })

  useEffect(() => {
    if (data !== null) {
      const qty = data.getShopList.items.reduce((acc: number, item: any) => acc + item.quantity, 0)
      setTotalCost(formatPrice(data.getShopList.totalCost))
      setNumItems(qty)
    }
  }, [data])

  return (
    <header className='sticky top-0 z-50 h-20 flex flex-wrap items-center justify-between px-3 sm:px-4 bg-white shadow-sm shadow-slate-300'>
      <div className='relative max-w-40 hidden sm:block '>
        <Link to='/'>
          <img className='block h-8 md:h-16 w-auto' src={logo} alt='Savvie Home' />
        </Link>
      </div>

      <div className='sm:flex-grow sm:max-w-sm'>
        <Search />
      </div>

      <div className='relative flex flex-grow sm:grow-0 justify-between items-center sm:space-x-3'>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={clsx(numItems > 0 && 'cursor-pointer', 'flex flex-row flex-grow items-center')}
          onClick={() => {
            numItems > 0 && setOpenBasket(!openBasket)
          }}
          role='button'
          tabIndex={0}
        >
          <div className='flex flex-row items-center justify-center sm:justify-normal flex-grow sm:w-48'>
            <CartButton numItems={numItems} openBasket={openBasket} setOpenBasket={setOpenBasket} />
            <div className='px-2 text-xl font-semibold text-secondary-600 w-20 hidden sm:inline-flex'>
              {totalCost}
            </div>
          </div>
        </div>
        <ProfileMenu />
      </div>
      <BasketSlide openBasket={openBasket} setOpenBasket={setOpenBasket} />
      <SearchSlide openSearch={openSearch} setOpenSearch={setOpenSearch} />
    </header>
  )
}

export default Header
