import { AutocompleteForm } from '../'

export function UserAddress(): JSX.Element {
  return (
    <div className=''>
      <div className='flex flex-col space-y-2 mb-6'>
        <div className=''>
          <AutocompleteForm />
        </div>
      </div>
    </div>
  )
}

export default UserAddress
