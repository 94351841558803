import React, { Fragment, useEffect, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { gql, useApolloClient, useMutation } from '@apollo/client'

import {
  DELETE_SHOPLIST_ITEM,
  GET_SHOPLIST,
  LIST_PRODUCT_FIELDS,
  SET_SHOPLIST_ITEM_QTY,
} from '../graphql'
import { formatPrice } from '../../../utils'
import { productQtyOptions } from '../utils'
import { QtySelectStyle, UtilSecButtonStyle, TextLinkStyle } from '../../../styles'
import { useAppSelector } from '../../../config'

interface IBasketSlideProps {
  setOpenBasket: (val: boolean) => void
  openBasket: boolean
}

export function BasketSlide({ openBasket, setOpenBasket }: IBasketSlideProps): JSX.Element {
  const client = useApolloClient()
  const [listItems, setListItems] = useState<any[]>([])
  const [listId, setListId] = useState<string | null>(null)
  const [totalCost, setTotalCost] = useState<string>('0.00')

  // FIXME: using both listId and shopListId is wrong
  const shopListId = useAppSelector((state) => state.shopList.listId)

  const data = client.readQuery({
    query: GET_SHOPLIST,
  })

  const [deleteShopListItem] = useMutation(DELETE_SHOPLIST_ITEM)

  const [setItemQty] = useMutation(SET_SHOPLIST_ITEM_QTY)

  const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const quantity = Number(event.target.value)
    const itemId = event.target.name

    client.cache.modify({
      id: client.cache.identify({ __typename: 'ShopList', _id: shopListId }),
      fields: {
        items(existingItems = [], { readField }) {
          return existingItems.map((ref: any) => {
            if (readField('_id', ref) === itemId) {
              const subTotal = Number(readField('unitPrice', ref)) * quantity
              const item = listItems.find((i) => i._id === itemId)
              client.cache.writeFragment({
                fragment: LIST_PRODUCT_FIELDS,
                fragmentName: 'ListProductFields',
                data: {
                  __typename: 'ShopListItem',
                  _id: item._id,
                  product: {
                    __typename: 'Product',
                    ...item.product,
                  },
                  quantity,
                  size: item.size,
                  subTotal: item.unitPrice * quantity,
                  title: item.title,
                  unitPrice: item.unitPrice,
                },
              })
              // now update the totalCost
              // We are making 2 separate cache.writeFrament calls because we need to update the totalCost
              // as well and I didn't want to have to calculate the totalCost in the 'fields' object
              // or do everything in one fragment and so it just made more sense this way.
              const newTotalCost = existingItems.reduce(
                (acc: number, item: any) => acc + Number(readField('subTotal', item)),
                0,
              )
              client.cache.writeFragment({
                id: client.cache.identify({ __typename: 'ShopList', _id: shopListId }),
                fragment: gql`
                  fragment NewShopList on ShopList {
                    totalCost
                  }
                `,
                data: {
                  __typename: 'ShopList',
                  totalCost: newTotalCost,
                },
              })
              return { ...ref, quantity, subTotal }
            }
            return ref
          })
        },
      },
    })

    await setItemQty({
      variables: {
        input: {
          listId,
          itemId,
          quantity,
        },
      },
    })
  }

  const handleDeleteItem = async (itemId: string): Promise<void> => {
    if (shopListId === null) {
      throw new Error('shopListId is null')
    }

    client.cache.modify({
      id: client.cache.identify({ __typename: 'ShopList', _id: shopListId }),
      fields: {
        items(existingItems = [], { readField }) {
          return existingItems.filter((ref: any) => readField('_id', ref) !== itemId)
        },
        totalCost(existingTotalCost = 0, { readField }) {
          return (
            existingTotalCost - listItems.find((ref) => readField('_id', ref) === itemId).subTotal
          )
        },
      },
    })

    deleteShopListItem({
      variables: { listId, itemId },
    })
  }

  useEffect(() => {
    if (data !== null) {
      setListId(data.getShopList._id)
      setTotalCost(formatPrice(data.getShopList.totalCost))
      setListItems(data.getShopList.items)
    }
  }, [data])

  return (
    <Transition.Root show={openBasket} as={Fragment}>
      <Dialog as='div' className='relative z-00' onClose={setOpenBasket}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-400 sm:duration-500'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-400 sm:duration-500'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                {/* Added the mt-16 to offset the size of the header */}
                <Dialog.Panel className='pointer-events-auto w-screen max-w-sm mt-20'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl border-l border-slate-300'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-gray-900 '>
                          <div className='-my-2'>
                            <div className='mb-3'>
                              List Total{' '}
                              <span className='text-black font-semibold'>{totalCost}</span>
                            </div>
                            <div className='flex flex-col'>
                              <Link
                                to='shopping-list'
                                className={TextLinkStyle}
                                onClick={() => setOpenBasket(false)}
                              >
                                View Shopping List
                              </Link>
                            </div>
                          </div>
                        </Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='rounded-md bg-white hover:text-secondary-500 hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2'
                            onClick={() => setOpenBasket(false)}
                          >
                            <span className='sr-only'>Close panel</span>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={2}
                              stroke='currentColor'
                              className='w-6 h-6 stroke-primary-600'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M6 18L18 6M6 6l12 12'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='relative mt-6 flex-1'>
                      <ul
                        // role='list'
                        className='divide-y divide-slate-200 border-slate-300 border-t border-b'
                      >
                        {listItems.length > 0 &&
                          listItems.map((item, index) => (
                            <li key={index} className='flex flex-row px-6 py-2 hover:bg-slate-50'>
                              <div className='flex flex-grow text-sm pr-3'>{item.title}</div>

                              <div className='flex items-center'>
                                <select
                                  id='qty'
                                  name={item._id}
                                  className={QtySelectStyle}
                                  value={item.quantity}
                                  onChange={handleSelectChange}
                                >
                                  {productQtyOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className='flex items-center ml-2'>
                                <button
                                  className={UtilSecButtonStyle}
                                  onClick={async () => await handleDeleteItem(item._id)}
                                  type='button'
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth='1.5'
                                    stroke='currentColor'
                                    className='w-5 h-5 stroke-slate-800'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                    />
                                  </svg>
                                </button>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default BasketSlide
