import { BuildingStorefrontIcon } from '@heroicons/react/20/solid'

import { StoreResultsCard as Card } from './StoreResultsCard'
import { IStoreProducts } from '../types'

interface ISearchStoreProps {
  storeProducts: IStoreProducts
}

export function StoreResults(props: ISearchStoreProps): JSX.Element {
  const { products, store } = props.storeProducts

  return (
    <>
      <div className='flex h-10 items-center'>
        <BuildingStorefrontIcon className='w-6 h-6 mr-1 text-primary-600' />
        <span className='font-semibold'>{store?.bannerName}</span>
        <span className='font-normal text-sm pl-3 hidden sm:inline-flex'>{store?.address}</span>
      </div>
      <div className='flex snap-x overflow-x-scroll hide-scroll-bar'>
        {products === null && (
          <div className='w-screen mt-2 h-8 text-slate-600'>
            No results found for store &quot;{store?.bannerName}&quot;
          </div>
        )}
        {products?.map((p) => <Card key={p._id} prod={p} />)}
      </div>
    </>
  )
}
export default StoreResults
