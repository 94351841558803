import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IUserState {
  loading: boolean
  // error: string | null
  // user: IUser | null
}

const initialState: IUserState = {
  loading: false,
  // error: null,
  // user: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export const { setLoading } = userSlice.actions

export default userSlice.reducer
