import { Link } from 'react-router-dom'

import { SectionContainer } from '../shared'
import { TextLinkStyle } from '../../../../styles'
import { IUserAddress } from '../Profile'

const tdStyle = 'px-4 py-3 whitespace-nowrap text-sm text-slate-700'

interface IProps {
  address: IUserAddress
}

export function Address({ address }: IProps): JSX.Element {
  return (
    <SectionContainer heading='Address'>
      <div className=''>
        <table className='min-w-full divide-y divide-slate-200'>
          <tbody className='bg-white divide-y divide-slate-200'>
            <tr>
              <td className={tdStyle}>Street Address</td>
              <td className={tdStyle}>{address.street}</td>
            </tr>
            <tr>
              <td className={tdStyle}>City</td>
              <td className={tdStyle}>{address.city}</td>
            </tr>
            <tr>
              <td className={tdStyle}>State/Province</td>
              <td className={tdStyle}>{address.stateProvince}</td>
            </tr>
            <tr>
              <td className={tdStyle}>Zip/Postal Code</td>
              <td className={tdStyle}>{address.postalCode}</td>
            </tr>
            <tr>
              <td className={tdStyle}>Country</td>
              <td className={tdStyle}>{address.country}</td>
            </tr>
          </tbody>
        </table>
        <div className='flex justify-end'>
          <Link to='/me/user-address' className={`${TextLinkStyle} text-base font-semibold`}>
            Change Address
          </Link>
        </div>
      </div>
    </SectionContainer>
  )
}

export default Address
