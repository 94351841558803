import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { RESET_USER_EMAIL } from '../../graphql'
import { PROFILE_EMAIL_UPDATED, PROFILE_SUBMISSION_FAILED } from '../../../../config/language'
import toast from 'react-hot-toast'
import { z } from 'zod'

import { SecInput, SecInputLabel, UtilPrimButtonStyle, TextLinkStyle } from '../../../../styles'
import { Button, InputErrMsg as ErrMsg, SectionContainer } from '../shared'

const FormData = z.object({
  email: z.string().email({ message: 'Invalid email address.' }),
})

export function EditEmail(): JSX.Element {
  const [error, setError] = useState<string | null>(null)

  const [resetUserEmail, { loading }] = useMutation(RESET_USER_EMAIL, {
    onError: (err) => {
      setError(err.message)
      toast.error(PROFILE_SUBMISSION_FAILED)
    },
    onCompleted: () => {
      toast.success(PROFILE_EMAIL_UPDATED)
    },
  })

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    setError(null)

    const target = e.target as typeof e.target & {
      email: { value: string }
    }

    const result = FormData.safeParse({
      email: target.email.value,
    })

    if (!result.success) {
      const errors = result.error.format() as any
      if (errors.email._errors.length > 0) {
        setError(errors.email._errors.join(', '))
      }
      return
    }

    void resetUserEmail({
      variables: {
        email: target.email.value,
      },
    })
  }

  return (
    <SectionContainer heading='Change Email Address'>
      <form onSubmit={handleSubmit}>
        <fieldset className='grid grid-cols-1 gap-y-6 mt-6 max-w-sm'>
          <div className='col-span-1'>
            <label htmlFor='email' className={SecInputLabel}>
              Email
            </label>
            <input autoFocus type='email' name='email' id='email' className={SecInput} required />
            {error !== null && <ErrMsg errStr={error} />}
          </div>

          <div className='col-span-1 flex flex-row justify-between items-center'>
            <Link to='/me/profile' className={TextLinkStyle}>
              &larr; Back to Profile
            </Link>
            <Button className={UtilPrimButtonStyle} type='submit'>
              {loading ? `Stand by...` : `Save Email`}
            </Button>
          </div>
        </fieldset>
      </form>
    </SectionContainer>
  )
}

export default EditEmail
