import React, { Fragment, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { ErrorMsgText, UtilSecButtonStyle } from '../../styles'

interface ModalProps {
  closeFunc: any
  isOpen: boolean
  message?: string | null
  MessageEle?: React.FC
}
export function ErrorModal({ closeFunc, isOpen, message, MessageEle }: ModalProps): JSX.Element {
  const cancelButtonRef = useRef(null)

  const MessageWrapper: React.FC<{ component: React.FC | undefined }> = ({
    component: Component,
  }) => {
    if (Component == null) return <></>
    return <Component />
  }

  useEffect(() => {
    const close = (e: { key: string }): void => {
      if (e.key === 'Escape') {
        closeFunc()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [closeFunc])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' initialFocus={cancelButtonRef} onClose={closeFunc}>
        <div className='fixed inset-0 bg-black/50' aria-hidden='true' />

        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div className='sm:flex sm:items-start'>
                  <div className='absolute right-4 top-3 inline-flex h-10 items-center'>
                    <button
                      className='p-1.5 py-1 text-xs font-semibold text-slate-600 bg-slate-100 rounded-md hover:bg-primary-200 hover:text-secondary-600'
                      type='button'
                      onClick={closeFunc}
                      ref={cancelButtonRef}
                    >
                      <span className='hidden sm:block text-[10px]'>ESC</span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={2}
                        stroke='currentColor'
                        className='block sm:hidden w-6 h-6 stroke-primary-600'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 18L18 6M6 6l12 12'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <ExclamationTriangleIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                  </div>
                  <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      Ooops... we&apos;ve encountered an error
                    </Dialog.Title>
                    <div className='mt-2'>
                      <MessageWrapper component={MessageEle} />
                      {message !== null && <p className={ErrorMsgText}>{message}</p>}
                    </div>
                  </div>
                </div>
                <div className='mt-3 flex justify-center text-center'>
                  <button type='button' className={UtilSecButtonStyle} onClick={closeFunc}>
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ErrorModal
