import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { DELETE_USER_ACCOUNT } from '../../graphql/mutations'
import { LoaderModal } from '../../../common'
import { useAppSelector } from '../../../../config'

export function DeleteAccount(): JSX.Element {
  const navigate = useNavigate()
  const hasDeleteAccountIntent = useAppSelector((state) => state.auth.userDeleteAccountIntent)

  const [deleteUser, { data, loading }] = useMutation(DELETE_USER_ACCOUNT)

  useEffect(() => {
    if (!hasDeleteAccountIntent) {
      navigate('/me/account')
      return
    }
    void deleteUser()
    if (data?.deleteUserAccount?.success) {
      navigate('/delete-account-confirmation')
    }
  }, [hasDeleteAccountIntent, navigate, deleteUser, data])

  if (loading) {
    return <LoaderModal isOpen={loading} message='Deleting account...' />
  }

  return <> </>
}

export default DeleteAccount
