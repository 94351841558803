import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

interface IModalProps {
  isOpen: boolean
  message?: string
}
export function LoaderModal({ isOpen, message }: IModalProps): JSX.Element {
  // const [isOpen, setIsOpen] = useState(true)

  // const setIsOpen = (isOpen: boolean): null => null

  function closeModal(): void {
    // setIsOpen(false)
    isOpen = false
  }

  /* function openModal(): void {
    setIsOpen(true)
  } */

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-slate-400 bg-opacity-80' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full transform overflow-hidden transition-all'>
                  <div className='bg-primary-50 bg-opacity-75 rounded-2xl w-96 mx-auto p-6'>
                    <div className='mb-2'>{message}</div>
                    <svg
                      className='w-44 h-44 mx-auto -mb-24 '
                      version='1.1'
                      id='L5'
                      xmlns='http://www.w3.org/2000/svg'
                      // xmlns:xlink='http://www.w3.org/1999/xlink'
                      x='0px'
                      y='0px'
                      viewBox='0 25 60 100'
                      enableBackground='new 0 0 0 0'
                      xmlSpace='preserve'
                    >
                      <circle fill='#4471F6' stroke='none' cx='6' cy='50' r='6'>
                        <animateTransform
                          attributeName='transform'
                          dur='1s'
                          type='translate'
                          values='0 15 ; 0 -15; 0 15'
                          repeatCount='indefinite'
                          begin='0.1'
                        />
                      </circle>
                      <circle fill='#4471F6' stroke='none' cx='30' cy='50' r='6'>
                        <animateTransform
                          attributeName='transform'
                          dur='1s'
                          type='translate'
                          values='0 10 ; 0 -10; 0 10'
                          repeatCount='indefinite'
                          begin='0.2'
                        />
                      </circle>
                      <circle fill='#4471F6' stroke='none' cx='54' cy='50' r='6'>
                        <animateTransform
                          attributeName='transform'
                          dur='1s'
                          type='translate'
                          values='0 5 ; 0 -5; 0 5'
                          repeatCount='indefinite'
                          begin='0.3'
                        />
                      </circle>
                    </svg>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default LoaderModal
