import { useEffect, useState } from 'react'

import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { IoLogoApple } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useScript, appleAuthHelpers } from 'react-apple-signin-auth'
import toast from 'react-hot-toast'

import { isStageMode } from '../../../../utils/envMode'
import { APPLE_CLIENT_ID, APP_PROD_URI, APP_STAGE_URI } from '../../../../config'
import { AUTH_CODE_REQUEST } from '../../graphql'
import { ErrorModal, LoaderModal } from '../../../common'
import { getShopListId } from '../../../products/shopListSlice'
import { setUserSession } from '../../authSlice'
import { SignupButtonIconStyle, SignupButtonTextStyle, SignupButtonStyle } from '../../../../styles'
import { useAppDispatch } from '../../../../config'

export function AppleSigninButton(): JSX.Element {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [isErrMessageOpen, setErrMessageOpen] = useState<boolean>(false)
  const [errMessage, setErrMessage] = useState<string>('')

  function closeErrMessage(): void {
    setErrMessageOpen(false)
  }

  const [authCodeRequest, { data, loading }] = useMutation(AUTH_CODE_REQUEST, {
    onError: () => {
      setShowLoader(false)
      setErrMessage('There was an error authenticating. Please try again.')
      setErrMessageOpen(true)
    },
  })

  let redirectURI: string
  if (isStageMode()) {
    redirectURI = `${APP_STAGE_URI}/`
  } else {
    redirectURI = `${APP_PROD_URI}/`
  }

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setShowLoader(true)
    const response = await appleAuthHelpers.signIn({
      authOptions: {
        clientId: APPLE_CLIENT_ID,
        redirectURI,
        scope: 'email name',
        state: 'myState', // TODO: can we remove this and the nonce?
        nonce: 'nonce',
        usePopup: true,
      },
      onError: (err: any) => {
        setShowLoader(false)
        console.error('Apple Signin error', err)
      },
    })

    if (response) {
      if (response.authorization.code) {
        authCodeRequest({
          variables: {
            input: {
              code: response.authorization.code,
              email: response.user?.email ?? '',
              firstName: response.user?.name.firstName ?? '',
              lastName: response.user?.name.lastName ?? '',
              provider: 'APPLE_WEB',
              redirectUri: redirectURI,
            },
          },
        })
      }
    } else {
      // assuming the onError handler will catch this
      console.error('Error performing apple signin.')
    }
  }

  const from = location.state?.from?.pathname || '/'

  useEffect(() => {
    if (data) {
      setShowLoader(false)
      if (data.authCodeRequest.success) {
        const sess = data.authCodeRequest.session
        dispatch(setUserSession(sess))
        dispatch(getShopListId())
        toast.success(
          <div className='text-slate-600'>
            Welcome back <span className='font-bold'>{sess.firstName}</span>! 👋
          </div>,
        )
        navigate(from, { replace: true })
      } else {
        setErrMessage(data.authCodeRequest.message)
        setErrMessageOpen(true)
      }
    }
  }, [data, dispatch, from, navigate])

  return (
    <>
      <button onClick={handleLogin} className={SignupButtonStyle} disabled={loading}>
        <IoLogoApple className={SignupButtonIconStyle} />
        <div className={SignupButtonTextStyle}>Apple</div>
        <ChevronRightIcon className={SignupButtonIconStyle} />
      </button>
      <LoaderModal isOpen={showLoader} message='Stand by...' />
      <ErrorModal isOpen={isErrMessageOpen} closeFunc={closeErrMessage} message={errMessage} />
    </>
  )
}

export default AppleSigninButton
