import { gql } from '@apollo/client'

import { LIST_ITEM_FIELDS, LIST_PRODUCT_FIELDS } from './fragments'

// FIXME: add the LIST_PRODUCT_FIELDS fragment to other queries

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($input: SearchProductsInput!) {
    searchProducts(input: $input) {
      _id
      brand
      category
      bestPrice
      listPrice
      promoPrice
      description
      imageUrl
      inStock
      ingredients
      nutritionInfo
      sizeText
      storeAddress
      storeBannerName
      storeChain
      storeId
      title
    }
  }
`

export const SEARCH_PRODUCTS_BY_STORE = gql`
  query SearchProductsByStore($input: SearchProductsInput!) {
    searchProductsByStore(input: $input) {
      products {
        _id
        brand
        category
        bestPrice
        listPrice
        promoPrice
        description
        imageUrl
        ingredients
        nutritionInfo
        sizeText
        store {
          address
          bannerName
        }
        title
      }
      storeId
      store {
        address
        bannerName
      }
    }
  }
`

export const SEARCH_PRODUCT_RECOMMENDATIONS = gql`
  query SearchProductRecommendations($input: SearchProductRecommendationsInput!) {
    searchProductRecommendations(input: $input) {
      _id
      brand
      category
      bestPrice
      listPrice
      promoPrice
      description
      imageUrl
      ingredients
      nutritionInfo
      sizeText
      store {
        address
        bannerName
      }
      title
    }
  }
`

// deprecated
// TODO: remove this query
export const GET_PRODUCT_DEALS = gql`
  query GetProductDeals($input: ProductDealsInput!) {
    getProductDeals(input: $input) {
      _id
      brand
      category
      bestPrice
      listPrice
      promoPrice
      description
      imageUrl
      ingredients
      nutritionInfo
      sizeText
      score
      title
      store {
        _id
        address
        bannerName
      }
    }
  }
`

export const GET_PRODUCT_RECOMMENDATIONS = gql`
  query GetProductRecommendations($resultLimit: Int) {
    getProductRecommendations(resultLimit: $resultLimit) {
      _id
      brand
      category
      bestPrice
      listPrice
      promoPrice
      description
      imageUrl
      ingredients
      nutritionInfo
      sizeText
      score
      title
      store {
        _id
        address
        bannerName
      }
    }
  }
`

export const GET_SHOPLIST = gql`
  ${LIST_PRODUCT_FIELDS}
  query GetShopList {
    getShopList {
      _id
      totalCost
      items {
        ...ListProductFields
      }
    }
  }
`

export const GET_SORTED_SHOPLIST = gql`
  ${LIST_PRODUCT_FIELDS}
  query GetSortedShopList($listId: ID!) {
    getSortedShopList(listId: $listId) {
      stores {
        items {
          ...ListProductFields
        }
        storeId
        storeAddress
        storeName
        storeTotal
      }
      totalCost
    }
  }
`

export const GET_SORTED_SHOPLIST_ARCHIVE = gql`
  ${LIST_PRODUCT_FIELDS}
  query GetSortedShopListArchive($listId: ID!) {
    getSortedShopListArchive(listId: $listId) {
      _id
      stores {
        items {
          ...ListProductFields
        }
        storeAddress
        storeId
        storeName
        storeTotal
        # title
      }
      totalCost
      createTS
      updateTS
    }
  }
`

export const GET_SHOPLISTS_ARCHIVE = gql`
  ${LIST_ITEM_FIELDS}
  query GetShopListsArchive {
    getShopListsArchive {
      _id
      items {
        ...ListItemFields
      }
      totalCost
      createdTS
      updateTS
    }
  }
`
