import { gql } from '@apollo/client'

export const AUTH_CODE_REQUEST = gql`
  mutation AuthCodeRequest($input: InputAuthCodeRequest!) {
    authCodeRequest(input: $input) {
      code
      message
      success
      session {
        userId
        sessionId
        email
        firstName
        lastName
        haveStoreIds
        roleBits
        ttl
      }
    }
  }
`

export const AUTH_WITH_EMAIL = gql`
  mutation AuthWithEmail($input: InputAuthWithEmail!) {
    authWithEmail(input: $input) {
      code
      message
      success
      session {
        userId
        sessionId
        email
        firstName
        lastName
        haveStoreIds
        roleBits
        ttl
      }
    }
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      code
      message
      success
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: InputResetPassword!) {
    resetPassword(input: $input) {
      code
      message
      success
      session {
        sessionId
        userId
        email
        firstName
        lastName
        haveStoreIds
        roleBits
        ttl
      }
    }
  }
`
