import { Fragment, useEffect, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import {
  // AdjustmentsHorizontalIcon,
  // ArrowLeftIcon,
  ArrowLeftOnRectangleIcon,
  Bars3BottomLeftIcon,
  BookmarkSquareIcon,
  BuildingStorefrontIcon,
  ShoppingCartIcon,
  CreditCardIcon,
  // StarIcon,
  // QuestionMarkCircleIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Outlet, Link, NavLink, useLocation } from 'react-router-dom'

import { deleteUserSession } from '../../auth/authSlice'
import { LoaderModal, ScrollToTop } from '../../common'
import { useAppDispatch, useAppSelector } from '../../../config'
import logo from '../../../assets/images/logo.svg'

const navLinks = [
  { name: 'Profile', href: 'profile', icon: UserIcon },
  { name: 'Saved Lists', href: 'saved-lists', icon: BookmarkSquareIcon },
  { name: 'My Stores', href: 'store-favs', icon: BuildingStorefrontIcon },
  // { name: 'Preferences', href: 'preferences', icon: AdjustmentsHorizontalIcon },
  // { name: 'Support', href: 'support', icon: ChatBubbleOvalLeftEllipsisIcon},
  { name: 'Account', href: 'account', icon: CreditCardIcon },
  { name: 'Shop', href: '/', icon: ShoppingCartIcon },
]
/* const secondaryNavigation = [
  { name: 'Help', href: '#', icon: QuestionMarkCircleIcon },
  // { name: 'Sign out', href: '#', icon: ArrowLeftOnRectangleIcon },
] */

export function Index(): JSX.Element {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')

  const loading = useAppSelector((state) => state.user.loading)

  const signOut = (): void => {
    dispatch(deleteUserSession())
  }

  useEffect(() => {
    if (location.pathname.startsWith('/me/archived-list')) {
      setTitle('Saved List')
      return
    }
    switch (location.pathname) {
      case '/me/account':
      case '/me/account/cancel-confirm':
      case '/me/account/payment-info':
      case '/me/account/plan':
      case '/me/account/portal':
      case '/me/account/status':
      case '/me/account/delete-account':
        setTitle('Account')
        break
      case '/me/store-favs':
      case '/me/store-favs/user-address':
        setTitle('My Stores')
        break
      case '/me/profile':
        setTitle('Profile')
        break
      case '/me/preferences':
        setTitle('Preferences')
        break
      case '/me/support':
        setTitle('Support')
        break
      case '/me/saved-lists':
        setTitle('Saved Lists')
        break
      default:
        setTitle('Profile')
        break
    }
  }, [location.pathname])

  return (
    <>
      <ScrollToTop />
      <div>
        {/* Static sidebar for desktop */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-40 md:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-slate-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-14 p-1'>
                      <button
                        type='button'
                        className='flex h-12 w-12 items-center justify-center rounded-full focus:bg-slate-600 focus:outline-none'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                        <span className='sr-only'>Close sidebar</span>
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex flex-shrink-0 items-center px-4'>
                    <img className='block h-12 w-auto' src={logo} alt='Savvie' />
                  </div>
                  <div className='mt-5 h-0 flex-1 overflow-y-auto'>
                    <nav className='flex h-full flex-col'>
                      <div className='space-y-1'>
                        {navLinks.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className={({ isActive }) =>
                              clsx(
                                'group border-l-4 py-2 px-3 flex items-center text-base font-medium',
                                isActive
                                  ? 'bg-slate-100 border-primary-600 text-primary-600'
                                  : 'border-transparent text-slate-600 hover:bg-slate-50 hover:text-slate-900',
                              )
                            }
                            onClick={() => setSidebarOpen(false)}
                          >
                            <item.icon
                              className='mr-4 flex-shrink-0 h-6 w-6 group-hover:text-slate-500'
                              aria-hidden='true'
                            />
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                      <div className='mt-auto space-y-1 pt-10'>
                        {/* {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className='group flex items-center border-l-4 border-transparent py-2 px-3 text-base font-medium text-slate-600 hover:bg-slate-50 hover:text-slate-900'
                          >
                            <item.icon
                              className='mr-4 h-6 w-6 text-slate-400 group-hover:text-slate-500'
                              aria-hidden='true'
                            />
                            {item.name}
                          </a>
                        ))} */}
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <nav className='flex flex-grow flex-col overflow-y-auto border-r border-slate-300 bg-primary-100 pt-5 pb-4'>
            <div className='flex flex-shrink-0 items-center px-4'>
              <Link to='/'>
                <img className='block h-16 w-auto' src={logo} alt='Savvie Logo' />
              </Link>
            </div>
            <div className='mt-5 flex-grow'>
              <div className='space-y-1'>
                {navLinks.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive, isPending }) =>
                      clsx(
                        'group border-l-4 py-2 px-3 flex items-center text-sm font-medium',
                        isActive
                          ? 'bg-white border-primary-700 text-primary-800'
                          : isPending
                            ? 'border-transparent text-slate-600 hover:text-slate-900 hover:bg-slate-50'
                            : '',
                      )
                    }
                  >
                    <item.icon className='mr-3 flex-shrink-0 h-6 w-6 group-hover:text-slate-500' />
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className='block w-full flex-shrink-0'>
              {/* {secondaryNavigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className='group flex items-center border-l-4 border-transparent py-2 px-3 text-sm font-medium text-slate-600 hover:bg-slate-50 hover:text-slate-900'
                >
                  <item.icon
                    className='mr-3 h-6 w-6 text-slate-400 group-hover:text-slate-500'
                    aria-hidden='true'
                  />
                  {item.name}
                </a>
              ))} */}
              <button
                className='group flex items-center border-l-4 border-transparent py-2 px-3 text-sm font-medium text-slate-600 hover:bg-slate-50 hover:text-slate-900'
                onClick={() => signOut()}
                type='submit'
              >
                <ArrowLeftOnRectangleIcon
                  className='mr-3 h-6 w-6 text-slate-400 group-hover:text-slate-500'
                  aria-hidden='true'
                />
                Sign out
              </button>
            </div>
          </nav>
        </div>

        {/* Content area */}
        <div className='md:pl-64'>
          <div className='mx-auto flex max-w-4xl flex-col'>
            <div className='sticky top-0 z-10 flex h-16 shrink-0 border-b border-slate-200 bg-white'>
              <button
                type='button'
                className='border-r border-slate-200 px-4 text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-500 md:hidden'
                onClick={() => setSidebarOpen(true)}
              >
                <span className='sr-only'>Open sidebar</span>
                <Bars3BottomLeftIcon className='h-6 w-6' aria-hidden='true' />
              </button>
              <div className='flex items-center ml-6 xl:ml-0 text-2xl sm:text-3xl font-medium tracking-tight text-slate-800'>
                {title}
              </div>
            </div>

            <main className='flex-1 px-4 sm:px-6'>
              <div className='relative mx-auto max-w-4xl mt-4 sm:mt-6 pb-16'>
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
      <LoaderModal isOpen={loading} />
    </>
  )
}

export default Index
