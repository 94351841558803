import { useEffect, useState } from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import placeHolder from '../../../assets/images/placeholder-image.png'

import {
  ACTIVATE_SHOPLIST,
  DELETE_SHOPLIST,
  GET_SHOPLISTS_ARCHIVE,
  GET_SORTED_SHOPLIST_ARCHIVE,
} from '../../products/graphql'
import { formatPrice, parseUTCDate } from '../../../utils'
import { IShopList } from '../../products/types'
import { LIST_DELETED } from '../../../config/language'
import { TextLinkStyle } from '../../../styles'
import Heading from './shared/heading'
import LoaderModal from '../../common/LoaderModalLt'

interface IItemMap {
  [productId: string]: boolean
}

const checkedStyle = 'opacity-30'

export function ArchivedList(): JSX.Element {
  const { listId } = useParams()
  const navigate = useNavigate()
  const [itemMap, setItemMap] = useState<IItemMap>({})
  const [isListComplete, setIsListComplete] = useState<boolean>(false)
  const [hasExpiredProducts, setHasExpiredProducts] = useState<boolean>(false)

  const { data, loading } = useQuery(GET_SORTED_SHOPLIST_ARCHIVE, {
    variables: { listId },
    onCompleted: (data) => {
      data.getSortedShopListArchive?.stores.forEach((store: any) => {
        store.items.forEach((item: any) => {
          setItemMap((prev) => ({
            ...prev,
            [item._id]: false,
          }))
        })
      })
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const shopList = data?.getSortedShopListArchive as IShopList | null
  const savedDate = shopList?.updateTS != null ? format(parseUTCDate(shopList.updateTS), 'PP') : ''

  const [deleteShopList] = useMutation(DELETE_SHOPLIST, {
    variables: { listId },
    refetchQueries: [{ query: GET_SHOPLISTS_ARCHIVE }],
  })

  const [activateShopList] = useMutation(ACTIVATE_SHOPLIST, {
    variables: { listId },
    refetchQueries: [{ query: GET_SHOPLISTS_ARCHIVE }],
    // Using onCompleted instead of optimisticResponse because we need to navigate to /shopping-list after shopping list is activated
    onCompleted: () => {
      // toast.success(LIST_ACTIVATED)
      // when navigating to /shopping-list App.tsx checks for userSessionId and calls getShopList()
      // thereby setting the shopList in the store
      navigate('/shopping-list')
    },
  })

  const handleBackToLists = (): void => {
    navigate('/me/saved-lists')
  }
  const handleActivateList = (): void => {
    void activateShopList()
  }

  const handleDeleteList = (): void => {
    void deleteShopList()
    toast.success(LIST_DELETED)
    navigate('/me/saved-lists')
  }

  const handleSetItemStatus = (productId: string): void => {
    setItemMap((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }))
  }

  useEffect(() => {
    // for some reason the itemMap has an undefined key initially, so we need to remove it
    /* for (const [key] of Object.entries(itemMap)) {
      if (key === 'undefined') {
        delete itemMap[key]
      }
    } */
    function listComplete(obj: Record<string, any>): boolean {
      if (Object.keys(obj).length === 0) return false
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          if (obj[key] === false) return false
        }
      }
      return true
    }

    if (shopList != null) {
      if (listComplete(itemMap)) {
        setIsListComplete(true)
      } else {
        setIsListComplete(false)
      }
    }
  }, [itemMap, shopList])

  // initialize itemMap
  useEffect(() => {
    if (shopList != null) {
      shopList.stores.forEach((store: any) => {
        store.items.forEach((item: any) => {
          if (item.product === null) {
            setHasExpiredProducts(true)
            return
          }
        })
      })
    }
  }, [shopList])

  if (listId == null) return <div>Invalid List ID</div>

  return (
    <>
      {loading ? (
        <LoaderModal isOpen={loading} />
      ) : (
        <div className='flex flex-col'>
          <Heading text={`Saved List from ${savedDate}`} />
          <div className='flex flex-row justify-start gap-x-4'>
            <button className={TextLinkStyle} type='button' onClick={handleBackToLists}>
              &larr; Back to Lists
            </button>
            {hasExpiredProducts === false && (
              <button className={TextLinkStyle} type='button' onClick={handleActivateList}>
                Activate List
              </button>
            )}
            <button className={TextLinkStyle} type='button' onClick={handleDeleteList}>
              Delete List
            </button>
          </div>

          <div
            className={clsx(
              'flex flex-row transition duration-200 ease-in-out text-lg font-semibold items-center gap-x-2 mt-0 mx-4 -mb-5 rounded-2xl shadow-md border-slate-200 p-0 bg-primary-100',
              isListComplete ? 'opacity-100' : 'opacity-0',
            )}
          >
            <CheckCircleIcon className='w-10 h-10 text-primary-600' />
            List Complete
          </div>

          {shopList?.stores.map((store: any) => (
            <div key={store.storeId} className='flex pt-2 first:pt-0'>
              <table className='w-full border-collapse'>
                <thead>
                  <tr className=''>
                    <th
                      colSpan={3}
                      className='py-4 text-left text-xl  text-baseline sm:text-xl font-medium'
                    >
                      {store.storeName}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {store.items.map((item: any) => (
                    <tr key={item._id} className='border-b border-slate-200'>
                      <td className='w-12 sm:w-20 text-center'>
                        <button
                          type='button'
                          className=''
                          onClick={() => handleSetItemStatus(item._id)}
                        >
                          <CheckCircleIcon
                            className={clsx(
                              'w-10 h-10 duration-200',
                              itemMap[item._id] ? 'text-primary-600' : 'text-slate-300',
                            )}
                          />
                        </button>
                      </td>
                      <td
                        className={clsx(
                          'w-20 sm:w-24 text-center duration-200 py-1',
                          itemMap[item._id] && checkedStyle,
                        )}
                      >
                        {item.product ? (
                          <img
                            className='inline-flex object-contain w-20 h-20 sm:w-24 sm:h-24'
                            src={item.product.imageUrl}
                            alt={item.product.title}
                          />
                        ) : (
                          <img
                            className='inline-flex object-contain w-12 h-12'
                            src={placeHolder}
                            alt='placeholder'
                          />
                        )}
                      </td>
                      <td
                        className={clsx(
                          'align-text-top pl-3 sm:pl-6 sm:py-2 duration-200 py-1.5',
                          itemMap[item._id] && checkedStyle,
                        )}
                      >
                        <div className='font-semibold text-sm sm:text-lg pr-2'>{item.title}</div>
                        <div className='text-sm sm:text-baseline'>{item.size}</div>
                        <div className='flex flex-row items-center text-sm'>
                          <div className='flex font-semibold'>{formatPrice(item.subTotal)}</div>
                          {item.quantity > 1 && (
                            <div className='flex text-sm sm:text-baseline ml-3'>
                              ({item.quantity} x {formatPrice(item.unitPrice)})
                            </div>
                          )}
                        </div>
                        {item.product === null && (
                          <div className='bg-yellow-100 w-72 pl-3 py-1 mt-1.5 text-sm rounded-md'>
                            This product is no longer available
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr className='border-b border-slate-600'>
                    <td colSpan={3} className='py-2 text-right text-lg font-semibold'>
                      <span className='text-base font-normal pr-4'>Store Total</span>
                      {formatPrice(store.storeTotal)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

          {shopList != null && shopList.totalCost > 0 && (
            <div className='mt-4 text-right text-xl font-semibold'>
              <span className='pr-4'>Total</span>
              {formatPrice(shopList.totalCost)}
            </div>
          )}

          <Link to='/me/saved-lists' className={TextLinkStyle}>
            &larr; Back to Lists
          </Link>
        </div>
      )}
    </>
  )
}

export default ArchivedList
