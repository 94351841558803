import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Loader } from '@googlemaps/js-api-loader'

import { InputStyle, SubmitButStyle } from '../../../styles/common'
import { SET_ADDRESS } from '../graphql'
import { extractAddressFromPlace } from '../../../utils'

export interface IAddress {
  city: string
  country: string
  latitude: number
  longitude: number
  postalCode: string
  stateProvince: string
  street: string
}

const addrDefaults: IAddress = {
  city: '',
  country: '',
  latitude: 0,
  longitude: 0,
  postalCode: '',
  stateProvince: '',
  street: '',
}

declare global {
  interface Window {
    google: any
  }
}

export function AutocompleteForm(): JSX.Element {
  const [isButtonActive, setIsButtonActive] = useState(false)
  const [isFieldDisabled, setIsFieldDisabled] = useState(true)
  const autoCompleteRef = useRef<any>(null)
  const inputRef = useRef<any>(null)
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()

  const [address, setAddress] = useState<IAddress>({
    city: '',
    country: '',
    latitude: 0,
    longitude: 0,
    postalCode: '',
    stateProvince: '',
    street: '',
  })

  const [submit, { data, loading, error }] = useMutation(SET_ADDRESS)

  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()
    toast.dismiss()

    const target = e.target as typeof e.target & {
      reset: () => null
    }

    void submit({
      variables: {
        address,
      },
    }).then(() => {
      target.reset()
    })
  }

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyAHqJkxNBMXjQcQnsjsAcMf0idrUgA-Cvs', // replace with your API key
      version: 'weekly',
      libraries: ['places'],
    })

    const options = {
      componentRestrictions: { country: ['ca', 'us'] },
      fields: ['address_components', 'geometry', 'formatted_address', 'name'],
      types: ['premise', 'street_address'],
    }

    void loader.load().then(async () => {
      autoCompleteRef.current = await new window.google.maps.places.Autocomplete(
        inputRef.current,
        options,
      )
      autoCompleteRef.current.addListener('place_changed', () => {
        const place = autoCompleteRef.current.getPlace()
        if (place !== undefined) {
          const address2 = extractAddressFromPlace(place.address_components)
          address2.latitude = place.geometry.location.lat()
          address2.longitude = place.geometry.location.lng()
          if (address2 != null) {
            setIsButtonActive(true)
            setAddress(address2)
            setIsFieldDisabled(false)
          }

          if (submitButtonRef.current != null) {
            submitButtonRef.current.focus()
          }
        }
      })
    })
  }, [address])

  useEffect(() => {
    if (error != null) {
      if (error !== undefined && error.graphQLErrors.length > 0) {
        toast.error(error.graphQLErrors[0].message)
      }
    }
  }, [error])

  useEffect(() => {
    if (data != null && data.setAddress.success === true) {
      toast.success('Address saved')
      setAddress(addrDefaults)
      navigate('/me/store-favs')
    }
  }, [data, navigate])

  // for field names best suited for autocomplete,
  // see: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#examples
  return (
    <div className='w-96'>
      <form onSubmit={handleSubmit}>
        <fieldset className='grid grid-cols-1 gap-x-6 px-1 mt-4 gap-y-6'>
          <div className='block rounded-md p-3 border-2 border-primary-500 space-y-4'>
            <p className='text-2xl text-slate-900'>Search address</p>
            <input autoFocus className={InputStyle} ref={inputRef} />
          </div>

          <div className=''>
            <input
              autoComplete='street-address'
              className={InputStyle}
              disabled={isFieldDisabled}
              id='street'
              name='street'
              onChange={handleAddressChange}
              placeholder='Street Address'
              required
              type='text'
              value={address.street}
            />
          </div>

          <div className=''>
            <input
              autoComplete='address-level2'
              className={InputStyle}
              disabled={true}
              id='city'
              name='city'
              onChange={handleAddressChange}
              placeholder='City'
              required
              type='text'
              value={address.city}
            />
          </div>

          <div className=''>
            <input
              autoComplete='address-level1'
              className={InputStyle}
              disabled={true}
              id='stateProvince'
              name='stateProvince'
              onChange={handleAddressChange}
              placeholder='State / Province'
              required
              type='text'
              value={address.stateProvince}
            />
          </div>

          <div className=''>
            <input
              autoComplete='postal-code'
              className={InputStyle}
              disabled={isFieldDisabled}
              id='postalCode'
              name='postalCode'
              onChange={handleAddressChange}
              placeholder='Zip / Postal Code'
              required
              type='text'
              value={address.postalCode}
            />
          </div>

          <div className=''>
            <input
              autoComplete='country'
              className={InputStyle}
              disabled={true}
              id='country'
              name='country'
              onChange={handleAddressChange}
              placeholder='Country'
              required
              type='text'
              value={address.country}
            />
          </div>

          <button
            className={`${SubmitButStyle} w-42 disabled:opacity-75`}
            disabled={!isButtonActive}
            ref={submitButtonRef}
          >
            {loading ? 'Stand by...' : 'Submit'}
          </button>
        </fieldset>
      </form>
    </div>
  )
}

export default AutocompleteForm
