import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import LoaderModal from '../../common/LoaderModalLt'
// import Card from './Card'
import { SEARCH_PRODUCTS_BY_STORE } from '../graphql/queries'
import { IProduct } from '../types'
import { StoreResults } from './StoreResults'

export function SearchByStore(): JSX.Element {
  const { term } = useParams()
  const haveProducts = useRef(false)

  let products: IProduct[] = []

  const [getProducts, { loading, data }] = useLazyQuery(SEARCH_PRODUCTS_BY_STORE, {
    // this still has 2 renders
    /* onCompleted: (data) => {
      console.log('onCompleted data: ', data)
      products = data.searchProductsByStore
    }, */
    /*  onError: (err) => {
      console.log('graphql error: ', err)
    }, */
  })

  useEffect(() => {
    haveProducts.current = false
    if (term !== '') {
      void getProducts({ variables: { input: { term } } })
    }
  }, [getProducts, haveProducts, term])

  if (
    Boolean(data) &&
    Boolean(data.searchProductsByStore) &&
    data.searchProductsByStore.length > 0
  ) {
    products = data.searchProductsByStore
    haveProducts.current = true
  }

  return (
    <>
      <div className='flex py-2 pl-6  text-slate-600 text-xl font-semibold'>
        Results for &quot;{term}&quot;
      </div>
      {/* <div className='flex flex-col overflow-x-hidden px-2 sm:px-6 pt-2 pb-6'> */}
      <div className='flex flex-col px-2 sm:px-6 pt-2 pb-6 min-h-screen'>
        {/* {haveProducts.current && products.map((p: any) => <Card key={p._id} prod={p} />)} */}
        {!loading && !haveProducts.current && (
          <div className='w-screen mt-6 h-16 text-center'>
            No results found for &quot;{term}&quot;
            <br />
            Please try a different search
          </div>
        )}
        {haveProducts.current &&
          products.map((p: any) => <StoreResults key={p.storeId} storeProducts={p} />)}
      </div>
      <LoaderModal isOpen={loading} />
    </>
  )
}

export default SearchByStore
