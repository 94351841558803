import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import Lottie from 'react-lottie'
import animationData from '../../assets/lottie/ldr-wink-day.json'

const loaderOpts = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

interface IModalProps {
  isOpen: boolean
  message?: string
}
export function LoaderModal({ isOpen, message }: IModalProps): JSX.Element {
  const setIsOpen = (): null => null

  function closeModal(): void {
    setIsOpen()
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-white/50' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full transform overflow-hidden transition-all'>
                  <div className=''>
                    <div className='text-slate-600 font-semibold'>{message}</div>
                    <Lottie options={loaderOpts} height={200} width={200} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default LoaderModal
