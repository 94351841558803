import { Outlet } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useQuery } from '@apollo/client'

import { GET_USER_ACCOUNT_INFO } from '../../graphql/queries'
import { LoaderModal } from '../../../common'

export function Account(): JSX.Element {
  const { data, loading } = useQuery(GET_USER_ACCOUNT_INFO, { fetchPolicy: 'network-only' })
  if (loading) {
    return <LoaderModal isOpen={loading} message='Stand by fetching your account information' />
  }
  let publishableKey = ''

  if (import.meta.env.PROD) {
    publishableKey = data.stripeConfig.publishableKey
  } else {
    publishableKey = import.meta.env.VITE_STRIPE_PUBKEY
  }
  const stripePromise = loadStripe(publishableKey)

  if (stripePromise === null) {
    return <LoaderModal isOpen={loading} message='Stand by loading...' />
  }

  return (
    <>
      <div className='flex flex-col'>
        <div className=''>
          <div className=''>
            <Elements stripe={stripePromise}>
              <Outlet />
            </Elements>
          </div>
        </div>
      </div>
    </>
  )
}

export default Account
