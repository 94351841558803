import { gql } from '@apollo/client'

export const GET_USER = gql`
  query GetUser($id: ID!) {
    getUser(_id: $id) {
      _id
      email
      firstName
      lastName
    }
  }
`

export const GET_USER_SESSION = gql`
  query GetUserSession($sessionId: String!) {
    getUserSession(sessionId: $sessionId) {
      sessionId
      userId
      email
      firstName
      lastName
      haveStoreIds
      roleBits
      ttl
    }
  }
`
