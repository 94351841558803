import { Fragment } from 'react'

import { useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'

import { UtilPrimButtonStyle, UtilSecButtonStyle } from '../../../../styles'
import { deleteUserSession, setUserDeleteAccountIntent } from '../../../auth/authSlice'
import { useAppDispatch } from '../../../../config'

interface IModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export function DeleteAccountConfirmModal({ isOpen, setIsOpen }: IModalProps): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const closeModal = (): void => {
    setIsOpen(false)
  }

  const handleLogout = (e: React.SyntheticEvent) => {
    e.preventDefault()
    dispatch(deleteUserSession())
    dispatch(setUserDeleteAccountIntent(true))
    navigate('/me/account/delete-account')
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title as='h3' className='text-xl font-medium leading-6 text-slate-800'>
                    Confirm Delete Account
                  </Dialog.Title>
                  <div className='text-sm my-3'>
                    <span className='font-semibold'>Warning:</span> Once your account has been
                    deleted all your shopping lists and other information will be permanently
                    deleted.
                  </div>
                  <div className='text-sm my-3'>
                    <span className='font-semibold'>Note:</span> You will be required to
                    authenticate before your account is deleted.
                  </div>

                  <div className='flex flex-row justify-center space-x-4 mt-4 w-'>
                    <button
                      type='button'
                      className={`${UtilPrimButtonStyle} w-56`}
                      onClick={handleLogout}
                    >
                      Yes Delete My Account
                    </button>
                    <button type='button' className={UtilSecButtonStyle} onClick={closeModal}>
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default DeleteAccountConfirmModal
