import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

interface InfoTipProps {
  children: JSX.Element
}

export function InfoPopover({ children }: InfoTipProps): JSX.Element {
  return (
    <>
      <Popover className='relative'>
        {() => (
          <>
            <Popover.Button className='group inline-flex items-center rounded-md p-1 focus:outline-none'>
              <InformationCircleIcon className='h-5 w-5 text-primary-600 group-hover:text-pink-600 transition duration-150 ease-in-out' />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute left-10 top-1.5 z-10 max-w-sm -translate-y-1/2 transform lg:max-w-xl '>
                <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-primary-100 p-3 pl-4'>
                  <div className='relative grid text-sm text-primary-900'>{children}</div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  )
}

export default InfoPopover
