import React, { useEffect, useRef } from 'react'

import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'
import toast from 'react-hot-toast'

import { LinkedHeader as Header, Footer } from '../layout/index'
import { AWS_REGION, AWS_SAVVIE_CDN_BUCKET } from '../../config'
import { ScrollToTop } from '../common'

export const MarkdownFile: React.FC = () => {
  const [markdownContent, setMarkdownContent] = React.useState<string | null>(null)
  const { file } = useParams()
  // const calledOnce = useRef(false)
  const fileNmReq = useRef('')

  let fileNm: string = ''
  if (file !== undefined) {
    fileNm = `markdown/${file}.md`
  }

  useEffect(() => {
    // to prevent this hook from firing mulitple times, we check if the fileNm is the same as the previous one
    if (fileNmReq.current === fileNm) return
    fileNmReq.current = fileNm

    const s3 = new S3Client({
      region: AWS_REGION,
      credentials: {
        accessKeyId: import.meta.env.VITE_MD_AKEY,
        secretAccessKey: import.meta.env.VITE_MD_SAKEY,
      },
    })

    const fetchFile = async (): Promise<void> => {
      const cmd = new GetObjectCommand({
        Bucket: AWS_SAVVIE_CDN_BUCKET,
        Key: fileNm,
      })

      try {
        const response = await s3.send(cmd)
        const str = await response.Body?.transformToString()
        setMarkdownContent(str ?? '')
        console.log('setnew fileNm: ', fileNm)
      } catch (error) {
        console.log('error: ', error)
        toast.error('Error fetching file.\nPlease contact the administrator.')
      }
    }

    void fetchFile()
  }, [fileNm, markdownContent])

  if (markdownContent == null) {
    return <div className='font-semibold text-3xl m-6'>Loading...</div>
  }

  return (
    <>
      <ScrollToTop />
      <div className='min-h-full  bg-primary-50'>
        <Header />
        <div className='markdown max-w-4xl mx-auto my-12 bg-white rounded-lg shadow-sm shadow-slate-300 border-1 border-slate-500 p-6'>
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default MarkdownFile
