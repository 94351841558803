import { IIngredients, INutritionComponents, INutriFact } from '../types'

export function parseNutriFacts(p: any): INutritionComponents | null {
  const ret = {} as INutritionComponents

  if (!(p.components !== null && p.components !== undefined && p.components.length > 0)) {
    console.error('Invalid data: ', p)
    return null
  }
  // console.log('p: ', p.components[0].preparationStates[0].servingSize)
  ret.servingSize = p.components[0].preparationStates[0].servingSize
  // console.log('p.ingredients: ', p.components[0])
  // ret.ingredients = p.components[0].ingredients

  const data = p.components[0].preparationStates[0].nutriFacts
  const tf: Record<string, INutriFact> = {}

  data.forEach((item: any) => {
    const tmp = {
      dailyValue: item.dailyValue,
      name: item.name,
      value: `${item.value}${item.abbreviation}`,
    }
    tf[item.name] = tmp as INutriFact
  })

  ret.nutriFacts = tf

  return ret
}

// TODO: should return an error if the data is invalid
export function parseIngredients(p: any): IIngredients {
  let ret: IIngredients = {}
  if (p.components === undefined) return ret
  const ingredients = p.components[0].ingredients
  ret = { ingredients }
  const reqdKeys = [
    'allergens',
    'fatFree',
    'glutenFree',
    'kosher',
    'lowFat',
    'nonGmo',
    'organic',
    'productWarning',
  ]

  reqdKeys.forEach((key) => {
    // eslint-disable-next-line
      // @ts-ignore
    if (p[key] !== undefined) {
      // eslint-disable-next-line
      // @ts-ignore
      ret[key as keyof IIngredients] = p[key]
    }
  })

  return ret
}

export function range(start: number, end: number): number[] {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

interface IOption {
  value: number
  label: string
}

export const productQtyOptions: IOption[] = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
]

export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
