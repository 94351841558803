import React, { useEffect, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import clsx from 'clsx'
import { useMutation } from '@apollo/client'

import { Container, ErrMsg, Title } from './Common'
import { ErrorModal } from '../../common/ErrorModal'
import { HeaderCentered as Header } from '../../layout/index'
import { InputStyle, SubmitButStyle, TextLinkStyle } from '../../../styles/common'
import { REQUEST_PASSWORD_RESET } from '../graphql'

interface IFormErrors {
  email: string | false
}

const fldErrsDefaults: IFormErrors = {
  email: false,
}

const FormData = z.object({
  email: z.string().email(),
})

export function RequestPasswordReset(): JSX.Element {
  const navigate = useNavigate()

  const [fieldErrs, setFieldErr] = useState<IFormErrors>(fldErrsDefaults)
  const [resultError, setResultError] = useState<string | null>(null)
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false)

  function closeErrorModal(): void {
    setIsErrorOpen(false)
  }

  function openErrorModal(): void {
    setIsErrorOpen(true)
  }

  const [submit, { data, loading }] = useMutation(REQUEST_PASSWORD_RESET)

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()

    setFieldErr(fldErrsDefaults)

    const target = e.target as typeof e.target & {
      email: { value: string }
      reset: () => null
    }

    const result = FormData.safeParse({
      email: target.email.value,
    })

    if (!result.success) {
      const errors = result.error.format() as any
      for (const key in errors) {
        if (key === '_errors') continue
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          setFieldErr((fieldErrs) => ({
            ...fieldErrs,
            [key]: errors[key]._errors.join(', '),
          }))
        }
      }
      return
    }

    void submit({
      variables: {
        email: target.email.value,
      },
    })

    target.reset()
  }

  useEffect(() => {
    if (data !== undefined) {
      console.log('data: ', data)
      if (data.requestPasswordReset.success === true) {
        navigate('/request-prok')
      }
      if (data.requestPasswordReset.success === false) {
        setResultError("The email address you entered doesn't match any account. Please try again.")
        openErrorModal()
      }
    }
  }, [data, navigate])

  return (
    <div className='bg-white h-screen md:bgimg'>
      <Header />
      <Container>
        <Title title='Reset Password' />
        <div className='mt-4 text-slate-700'>Please enter your email address</div>
        <form onSubmit={handleSubmit} className='my-4'>
          <fieldset className='grid grid-cols-1 gap-x-6 px-1 mt-4 gap-y-6'>
            <div className=''>
              <input
                autoFocus
                autoComplete='off'
                type='email'
                name='email'
                id='email'
                className={clsx(InputStyle, fieldErrs.email !== false && 'border-red-600')}
                required
                placeholder='Email'
                // defaultValue='ron@webbtech.net'
              />
              {fieldErrs.email !== false && <ErrMsg errStr={fieldErrs.email} />}
            </div>
            <div className='flex justify-center'>
              <button className={`${SubmitButStyle} w-36 disabled:opacity-75`} disabled={loading}>
                {loading ? 'Stand by...' : 'Submit'}
              </button>
            </div>
          </fieldset>
        </form>
        <Link
          to='/signin'
          className={`${TextLinkStyle} flex mt-6 text-sm justify-center w-fit mx-auto`}
        >
          &larr; Back to Sign In
        </Link>
      </Container>
      <ErrorModal isOpen={isErrorOpen} closeFunc={closeErrorModal} message={resultError} />
    </div>
  )
}

export default RequestPasswordReset
