import { IoMail } from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import { SignupButtonIconStyle, SignupButtonTextStyle, SignupButtonStyle } from '../../../../styles'

export function EmailSigninButton(): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()

  const from = location.state?.from?.pathname || '/'

  return (
    <button
      className={SignupButtonStyle}
      onClick={() =>
        navigate('/email-signin', { state: { from: { pathname: from } }, replace: true })
      }
    >
      <IoMail className={SignupButtonIconStyle} />
      <div className={SignupButtonTextStyle}>Email</div>
      <ChevronRightIcon className={SignupButtonIconStyle} />
    </button>
  )
}

export default EmailSigninButton
