import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ls from 'localstorage-slim'

import { SHOP_LIST_ID_KEY, USER_SESSION_KEY, USER_SESSION_ID_KEY } from '../../config'

/* export const RoleBitFreePlan: number = 1
export const RoleBitBasicPlan: number = 2
export const RoleBitPremiumPlan: number = 4
export const RoleBitMarketing: number = 8
export const RoleBitAdmin: number = 16
export const RoleBitSuperUser: number = 32
export const RoleBitStageUser: number = 64
*/

export const RoleBitsSubsActive: number = 1
export const RoleBitsPlan1: number = 2
export const RoleBitsPlan2: number = 4
export const RoleBitsPlan3: number = 8
export const RoleBitsMarketing: number = 16
export const RoleBitsAdmin: number = 32
export const RoleBitsSuperUser: number = 64
export const RoleBitsStageUser: number = 128

export const RoleBitsAr = [
  RoleBitsPlan1,
  RoleBitsPlan2,
  RoleBitsPlan3,
  RoleBitsMarketing,
  RoleBitsAdmin,
  RoleBitsSuperUser,
  RoleBitsStageUser,
]

export interface IUserSession {
  userId: string
  sessionId: string
  email: string
  firstName: string
  lastName: string
  haveStoreIds: boolean
  roleBits: number
  ttl: string
  __typename?: string
}

const allowedKeys: Array<keyof IUserSession> = [
  'email',
  'firstName',
  'lastName',
  'haveStoreIds',
  'roleBits',
]

export interface AuthState {
  userSession: IUserSession | null
  userDeleteAccountIntent: boolean | null
}

const initialState: AuthState = {
  userSession: null,
  userDeleteAccountIntent: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    deleteUserSession: (state) => {
      ls.remove(USER_SESSION_KEY)
      ls.remove(USER_SESSION_ID_KEY)
      ls.remove(SHOP_LIST_ID_KEY)
      state.userSession = null
    },
    getUserSession: (state) => {
      const userSession: IUserSession | null = ls.get(USER_SESSION_KEY)
      if (userSession !== null) {
        state.userSession = userSession
      }
    },
    setUserSession: (state, action: PayloadAction<IUserSession>) => {
      const sess = Object.assign({}, action.payload)
      delete sess.__typename
      ls.set(USER_SESSION_KEY, sess)
      ls.set(USER_SESSION_ID_KEY, sess.sessionId)
      state.userSession = sess
    },
    setUserSessionValue: (
      state,
      action: PayloadAction<{ key: keyof IUserSession; value: string | boolean }>,
    ) => {
      if (state.userSession !== null) {
        const userSession: IUserSession | null = ls.get(USER_SESSION_KEY)
        if (userSession !== null && allowedKeys.includes(action.payload.key)) {
          state.userSession = { ...userSession, [action.payload.key]: action.payload.value }
          ls.set(USER_SESSION_KEY, state.userSession)
        }
      }
    },
    setUserDeleteAccountIntent: (state, action: PayloadAction<boolean>) => {
      state.userDeleteAccountIntent = action.payload
    },
  },
})

export const {
  deleteUserSession,
  getUserSession,
  setUserSession,
  setUserSessionValue,
  setUserDeleteAccountIntent,
} = authSlice.actions

export default authSlice.reducer
