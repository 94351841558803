import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { TextLinkStyle } from '../../../styles'

export function Footer(): JSX.Element {
  return (
    <div className='h-12 sm:h-36 bg-white border-t border-slate-200 p-3'>
      <div className='flex justify-center mx-auto space-x-7 sm:mt-4'>
        <Link to='/' className={clsx(TextLinkStyle, 'text-center text-sm sm:text-baseline')}>
          Home
        </Link>
        <Link
          to='/f/terms-conditions'
          className={clsx(TextLinkStyle, 'text-center text-sm sm:text-baseline')}
        >
          Terms & Conditions
        </Link>
        <Link
          to='/f/privacy-policy'
          className={clsx(TextLinkStyle, 'text-center text-sm sm:text-baseline')}
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  )
}

export default Footer
