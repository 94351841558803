import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// TODO: replace 'any' with appropriate type
function SearchIcon(props: any): any {
  return (
    <svg aria-hidden='true' viewBox='0 0 20 20' {...props}>
      <path d='M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z' />
    </svg>
  )
}

/*
FIXME: add functionality to populate input if in params, possible ignore and manage from search page if already on `Search` screen
*/
export function Search(): JSX.Element {
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const [modifierKey, setModifierKey] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const setSearch = React.useCallback(
    (searchVal: string): void => {
      // console.log('search value: ', searchVal)
      navigate(`search/${encodeURI(searchVal)}`)
    },
    [navigate],
  )

  const handleClearSearch = (): void => {
    if (inputRef.current !== null) {
      inputRef.current.value = ''
      inputRef.current.focus()
    }
    setSearchTerm('')
  }

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    setModifierKey(/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? '⌘' : 'Ctrl ')
  }, [])

  useEffect(() => {
    // checking for command k (⌘k)
    const downHandler = (e: KeyboardEvent): void => {
      if (e.metaKey && e.key === 'k') {
        if (inputRef.current !== null) {
          inputRef.current.focus()
        }
      }

      if (e.key === 'Enter') {
        e.preventDefault()
        if (inputRef.current != null && inputRef.current.value.length >= 3) {
          setSearch(inputRef.current.value)
        }
      }
    }

    window.addEventListener('keydown', downHandler, false)

    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [setSearch])

  return (
    <label className='flex w-60 sm:w-auto relative'>
      <span className='sr-only'>Search</span>
      <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
        <SearchIcon className='w-6 fill-slate-500 group-hover:fill-primary-600' />
      </span>
      <input
        className='placeholder:italic placeholder:text-slate-500 block bg-primary-100 w-full border-primary-300 rounded-full py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-primary-500 focus:ring-primary-500 focus:ring-1 sm:text-sm'
        placeholder='Search products'
        ref={inputRef}
        type='text'
        name='search'
        onChange={handleSearchTermChange}
      />
      {searchTerm === '' && modifierKey !== '' && (
        <kbd className='ml-auto absolute inset-y-0 right-0 flex items-center pr-3 text-slate-400'>
          <kbd className='font-sans'>{modifierKey}</kbd>
          <kbd className='font-sans'>K</kbd>
        </kbd>
      )}
      {searchTerm.length > 0 && (
        <div className='ml-auto absolute inset-y-0 right-0 flex items-center pr-3 text-slate-400'>
          <button
            type='button'
            className='hover:text-secondary-500 hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2'
            onClick={handleClearSearch}
          >
            <span className='sr-only'>Close panel</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={2}
              stroke='currentColor'
              className='w-6 h-6 stroke-slate-500'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
            </svg>
          </button>
        </div>
      )}
    </label>
  )
}

export default Search
