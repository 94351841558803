import { gql } from '@apollo/client'

export const LIST_PRODUCT_FIELDS = gql`
  fragment ListProductFields on ShopListItem {
    _id
    quantity
    size
    subTotal
    title
    unitPrice
    product {
      _id
      brand
      bestPrice
      listPrice
      promoPrice
      imageUrl
      sizeText
      title
    }
  }
`

export const LIST_ITEM_FIELDS = gql`
  fragment ListItemFields on ShopListItem {
    _id
    quantity
    size
    subTotal
    title
    unitPrice
  }
`

export const PRODUCT_FIELDS = gql`
  fragment ProductFields on Product {
    _id
    brand
    category
    bestPrice
    listPrice
    promoPrice
    description
    imageUrl
    ingredients
    nutritionInfo
    sizeText
    store {
      address
      bannerName
    }
    title
  }
`
