enum EnvMode {
  Development = 'development',
  Production = 'production',
  Test = 'test',
  Staging = 'staging',
}
function getEnvMode(): EnvMode | undefined {
  if (window.location.hostname === 'localhost' || import.meta.env.DEV) {
    return EnvMode.Development
  } else if (window.location.hostname.includes('stage')) {
    return EnvMode.Staging
  } else if (import.meta.env.PROD) {
    return EnvMode.Production
  }
}
function isDevMode(): boolean {
  return getEnvMode() === 'development'
}
function isProdMode(): boolean {
  return getEnvMode() === 'production'
}
function isTestMode(): boolean {
  return getEnvMode() === 'test'
}
function isStageMode(): boolean {
  return getEnvMode() === 'staging'
}

// This function is used to determine if the app is in maintenance mode.
function isMaintenanceMode(): boolean {
  // return getEnvMode() === 'production'
  return false
}

export default {
  getEnvMode,
  isDevMode,
  isMaintenanceMode,
  isProdMode,
  isTestMode,
  isStageMode,
}
export { getEnvMode, isDevMode, isMaintenanceMode, isProdMode, isTestMode, isStageMode }
