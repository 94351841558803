import List from '../../../assets/images/list-icon.svg'

// FIXME: interface duplicated
interface IBasketSlideProps {
  setOpenBasket: (val: boolean) => void
  openBasket: boolean
  numItems: number | null
}

export function CartButton({
  numItems,
  openBasket,
  setOpenBasket,
}: IBasketSlideProps): JSX.Element {
  const icon = <img className='relative h-12 sm:h-14' src={List} alt='Shopping List' />

  return (
    <button
      className='flex'
      onClick={() => {
        numItems !== null && numItems > 0 && setOpenBasket(!openBasket)
      }}
    >
      {icon}
      <span className='absolute inline-flex justify-center text-xl font-semibold w-10 sm:left-1.5 top-3 sm:top-4 text-secondary-600'>
        {numItems}
      </span>
    </button>
  )
}

export default CartButton
