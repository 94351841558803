import { forwardRef, ComponentProps } from 'react'

// FIXME: this is a bit of a hack, leaving the empty last param
export const Button = forwardRef<HTMLButtonElement, Omit<ComponentProps<'button'>, ''>>(
  ({ children, className, ...rest }, ref) => {
    const defaultClasses =
      'justify-center rounded-md border border-transparent bg-primary-600 py-2 px-6 text-sm font-medium tracking-wider text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2'

    return (
      <button ref={ref} className={className ?? defaultClasses} {...rest}>
        {children}
      </button>
    )
  },
)

Button.displayName = 'Button'
export default Button
