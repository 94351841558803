import logo from '../../../assets/images/logo.svg'

export function BasicHeader(): JSX.Element {
  return (
    <header className='flex bg-white items-center h-20 shadow-sm shadow-slate-300'>
      <img className='relative h-16 pl-4' src={logo} alt='Savvie' />
    </header>
  )
}

export default BasicHeader
