import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { z } from 'zod'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'

import { Heading, Button, InputErrMsg as ErrMsg } from '../shared'
import { ErrorMsgText, SecInput, UtilPrimButtonStyle, TextLinkStyle } from '../../../../styles'
import { STRIPE_UPDATE_SUBSCRIPTION_WITH_COUPON } from '../../graphql'
import { ErrorModal } from '../../../common'
import { SUPPORT_EMAIL } from '../../../../config'

// good article on zod: https://catalins.tech/form-validation-with-react-hook-form-zod-typescript/
// using a schema and object when a simple regex would do is overkill, but here should we add other fields
export const CouponSchema = z.object({
  coupon: z.string().regex(/^[a-zA-Z0-9_-]{2,16}$/, { message: 'Invalid coupon code' }),
})

const ErrorMsg: React.FC = () => {
  const email = `mailto:${SUPPORT_EMAIL}?subject=Invalid Coupon Code`
  return (
    <p className={ErrorMsgText}>
      The coupon code you entered is invalid.
      <br />
      Please check the code and try again or{' '}
      <a className={TextLinkStyle} href={email}>
        contact support.
      </a>
    </p>
  )
}

export function Coupon(): JSX.Element {
  const [error, setError] = useState<string | null>(null)
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false)

  const [updateSubscription, { loading }] = useMutation(STRIPE_UPDATE_SUBSCRIPTION_WITH_COUPON, {
    onCompleted: (data: any) => {
      const ret = data.stripeUpdateSubscriptionWithCoupon
      if (ret.success === false) {
        openErrorModal()
        console.error(ret.message)
      } else {
        toast.success('Coupon applied!')
      }
    },
  })

  const closeErrorModal = (): void => {
    setIsErrorOpen(false)
  }

  const openErrorModal = (): void => {
    setIsErrorOpen(true)
  }

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault()

    setError(null)
    setIsErrorOpen(false)

    const target = e.target as typeof e.target & {
      coupon: { value: string }
      reset: () => null
    }

    const result = CouponSchema.safeParse({
      coupon: target.coupon.value,
    })

    if (!result.success) {
      const errors = result.error.format() as any
      setError(errors.coupon._errors[0])
      return
    }

    if (result.success) {
      void updateSubscription({ variables: { input: { couponId: result.data.coupon } } }).then(
        (data) => {
          if (data.data.stripeUpdateSubscriptionWithCoupon.success === true) {
            target.reset()
          }
        },
      )
    }
  }

  return (
    <>
      <div className='flex flex-col divide-y-slate-400 divide-y space-y-6'>
        <div className=''>
          <Heading text='Subscription Coupon' />
          <p className='text-sm text-slate-600'>
            Enter a coupon code to apply to your subscription.
          </p>

          <form onSubmit={handleSubmit}>
            <fieldset className='grid grid-cols-1 gap-y-6 mt-6 max-w-sm'>
              <div className='col-span-1'>
                <input
                  autoFocus
                  type='text'
                  name='coupon'
                  id='coupon'
                  className={SecInput}
                  required
                  placeholder='Coupon code'
                />
                {error !== null && <ErrMsg errStr={error} />}
              </div>

              <div className='col-span-1 flex flex-row justify-between items-center'>
                <Link to='/me/account' className={TextLinkStyle}>
                  &larr; Back to Account
                </Link>
                <Button className={UtilPrimButtonStyle} type='submit'>
                  {loading ? `Stand by...` : `Submit Coupon`}
                </Button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <ErrorModal isOpen={isErrorOpen} closeFunc={closeErrorModal} MessageEle={ErrorMsg} />
    </>
  )
}

export default Coupon
