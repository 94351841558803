import { Button, Heading } from './shared'

export function Preferences(): JSX.Element {
  return (
    <div className='flex flex-col divide-y-slate-400 divide-y space-y-6'>
      <div className='w-1/2'>
        <Heading text='Product Search' />

        <form>
          <fieldset className='space-y-5'>
            <div className='relative flex items-start'>
              <div className='flex h-5 items-center'>
                <input
                  id='bulk-stores'
                  aria-describedby='bulk-store-description'
                  name='bulk-stores'
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-pink-400'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='bulk-stores' className='font-medium text-slate-700'>
                  Shop Bulk Stores
                </label>
                <p id='bulk-store-description' className='text-slate-500'>
                  Allow bulk stores in search results
                </p>
              </div>
            </div>

            <div className='relative flex items-start'>
              <div className='flex h-5 items-center'>
                <input
                  id='prefer-organic'
                  aria-describedby='prefer-organic-description'
                  name='prefer-organic'
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-pink-400'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='prefer-organic' className='font-medium text-slate-700'>
                  Organic Foods
                </label>
                <p id='prefer-organic-description' className='text-slate-500'>
                  Prefer organic products in search results
                </p>
              </div>
            </div>

            <div className='relative flex items-start'>
              <div className='flex h-5 items-center'>
                <input
                  id='private-label'
                  aria-describedby='private-label-description'
                  name='private-label'
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-pink-400'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='private-label' className='font-medium text-slate-700'>
                  Private Label Brands
                </label>
                <p id='private-label-description' className='text-slate-500'>
                  Include private label brands in search results
                </p>
              </div>
            </div>

            <div className='relative flex justify-center pt-4'>
              <Button type='submit'>Save</Button>
            </div>
          </fieldset>
        </form>
      </div>
      {/* TODO */}
      <p className='bg-yellow-100'>
        NOTE: Add gluten free and vegan, separate items into product and food types. <br />
        Combine forms.
        <br />
        Expect more to come once we have a better look at the data
      </p>

      <div className='pt-8 w-1/2'>
        <Heading text='Notifications' />

        <form>
          <fieldset className='space-y-5'>
            <div className='relative flex items-start'>
              <div className='flex h-5 items-center'>
                <input
                  id='newsletter'
                  aria-describedby='newsletter-description'
                  name='newsletter'
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-pink-400'
                  checked
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='newsletter' className='font-medium text-slate-700'>
                  Newsletter
                </label>
                <p id='newsletter-description' className='text-slate-500'>
                  Occasional emails with latest news and information to help you shop better!
                </p>
              </div>
            </div>

            <div className='relative flex items-start'>
              <div className='flex h-5 items-center'>
                <input
                  id='sales-alerts'
                  aria-describedby='sales-alerts-description'
                  name='sales-alerts'
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-pink-400'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='sales-alerts' className='font-medium text-slate-700'>
                  Sales Alerts
                </label>
                <p id='sales-alerts-description' className='text-slate-500'>
                  Emails to alert you when sales are available in your area.
                </p>
              </div>
            </div>

            <div className='relative flex justify-center pt-4'>
              <Button type='submit'>Save</Button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
}

export default Preferences
