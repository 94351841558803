import { Link } from 'react-router-dom'

import { Heading } from '../shared'
import { TextLinkStyle, UtilSecButtonStyle } from '../../../../styles/common'

export function Plan(): JSX.Element {
  return (
    <div className='flex flex-col divide-y-slate-400 divide-y space-y-6'>
      <div className=''>
        <Heading text='Subscription Plans' />

        <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 pt-6 sm:mt-4 sm:pt-4 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
          <article className='flex max-w-xl flex-col items-start justify-between gap-y-4'>
            <h2 className='text-xl font-semibold text-primary-800'>Savvie Free</h2>
            <div className='text-sm text-slate-800'>
              Enjoy key features of <span className='font-semibold'>Savvie Free</span> forever, no
              credit card required.
            </div>
            <div className='font-semibold text-slate-700'>Cost: Free</div>
            <Link to='/me/account' className={UtilSecButtonStyle}>
              Subscribe
            </Link>
          </article>

          <article className='flex max-w-xl flex-col items-start justify-between gap-y-4'>
            <h2 className='text-xl font-semibold text-primary-800'>Savvie Plus</h2>
            <div className='text-sm text-slate-800'>
              Unlock the full power of Savvie by upgrading to the fully featured{' '}
              <span className='font-semibold'>Savvie Plus</span>.
            </div>
            <div className='font-semibold text-slate-700'>Cost: 5.00/month or 50.00/year</div>
            <Link to='/me/account' className={UtilSecButtonStyle}>
              Subscribe
            </Link>
          </article>
        </div>

        <div className='mt-6'>
          <Link to='/me/account' className={TextLinkStyle}>
            Compare Plans
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Plan
