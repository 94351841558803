import { useState } from 'react'

import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Link, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import { DELETE_SHOPLIST, GET_SHOPLISTS_ARCHIVE } from '../../products/graphql'
import { formatPrice, parseUTCDate } from '../../../utils'
import { LIST_DELETED } from '../../../config/language'
import { TextLinkStyle } from '../../../styles'
import { LoaderModal } from '../../common'

interface IListArchive {
  _id: string
  items: any[]
  totalCost: number
  updateTS: string
}

export function ArchivedLists(): JSX.Element {
  const navigate = useNavigate()
  const [historyList, setHistoryList] = useState<IListArchive[]>([])

  const { loading } = useQuery(GET_SHOPLISTS_ARCHIVE, {
    onCompleted: (data) => {
      if (data.getShopListsArchive === null) {
        toast.error('No shopping history found')
        // this is done in the event all shopping lists are deleted
        setHistoryList([])
        return
      }
      setHistoryList(data.getShopListsArchive)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [deleteShopList] = useMutation(DELETE_SHOPLIST, {
    refetchQueries: [{ query: GET_SHOPLISTS_ARCHIVE }],
  })

  const handleDeleteList = (listId: string): void => {
    void deleteShopList({
      variables: { listId },
    })
    toast.success(LIST_DELETED)
    const newList = historyList.filter((item) => item._id !== listId)
    setHistoryList(newList)
  }

  if (!loading && historyList.length === 0)
    return (
      <div className='h-screen'>
        <div className='my-2'>You have not saved a shopping list yet. </div>
        <Link to='/' className={`${TextLinkStyle} text-lg font-semibold inline-flex items-center`}>
          <ArrowLeftIcon
            className='mr-2 flex-shrink-0 h-6 w-6 group-hover:text-slate-500'
            aria-hidden='true'
          />
          Go Shopping
        </Link>
      </div>
    )

  return (
    <>
      {loading ? (
        <LoaderModal isOpen={true} message='Stand by loading lists' />
      ) : (
        <div className='max-w-[26rem]'>
          <table className='w-full'>
            <caption className='sr-only'>Saved Shopping Lists</caption>
            <thead className='text-sm'>
              <tr>
                <th scope='col' className='table-cell pb-1'>
                  Date
                </th>
                <th scope='col' className='table-cell pb-1'>
                  Products
                </th>
                <th scope='col' className='table-cell pb-1'>
                  Cost
                </th>
                <th scope='col' className='table-cell' />
              </tr>
            </thead>

            <tbody className=''>
              {historyList?.map((list) => (
                <tr
                  key={list._id}
                  className='even:bg-white odd:bg-slate-50 hover:bg-slate-100 border-b border-slate-300'
                >
                  <td className='py-2 pl-1 sm:pl-4'>
                    <button
                      className={TextLinkStyle}
                      onClick={() => navigate(`/me/archived-list/${list._id.toString()}`)}
                    >
                      <time dateTime={format(parseUTCDate(list.updateTS), 'P')}>
                        {format(parseUTCDate(list.updateTS), 'PP')}
                      </time>
                    </button>
                  </td>
                  <td className='table-cell text-center py-2'>{list.items.length}</td>
                  <td className='table-cell text-right py-2'>{formatPrice(list.totalCost)}</td>
                  {/* <td className='table-cell text-center pl-4 py-2 text-sm'>
                    <button
                      className={TextLinkStyle}
                      onClick={() => navigate(`/me/archived-list/${list._id.toString()}`)}
                    >
                      View
                    </button>
                  </td> */}
                  <td className='table-cell text-center py-2 pl-4 text-sm'>
                    <button className={TextLinkStyle} onClick={() => handleDeleteList(list._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}

              {/* {history.map((list) => (
                <tr
                  key={list.id}
                  className='even:bg-white odd:bg-slate-50 hover:bg-slate-100 border-b border-slate-300 hover:cursor-pointer'
                  onClick={() => navigate(`/me/saved-lists-detail/${list.id}`)}
                >
                  <td className='py-2 pl-4'>
                    <time dateTime={format(list.datetime, 'P')}>
                      {format(list.datetime, 'ccc LLL do, yyyy')}
                    </time>
                  </td>
                  <td className='table-cell text-center py-2'>{list.numItems}</td>
                  <td className='table-cell text-right py-2'>{list.cost}</td>
                  <td className='table-cell text-center p-0'>
                    <Link
                      to={`/me/saved-lists-detail/${list.id}`}
                      className=' text-pink-600 font-semibold p-2 hover:bg-pink-100'
                    >
                      View List
                    </Link>
                  </td>
                </tr>
              ))} */}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ArchivedLists
