import { forwardRef, ComponentPropsWithRef } from 'react'

import styles from './heading.module.css'

interface Props extends Omit<ComponentPropsWithRef<'header'>, 'className'> {
  text: string
}
export const Heading = forwardRef<HTMLHeadingElement, Props>(({ text, ...rest }, ref) => {
  return (
    <header ref={ref} className={styles.heading} {...rest}>
      {text}
    </header>
  )
})

Heading.displayName = 'Heading'
export default Heading
