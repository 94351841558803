import { createBrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { App, ErrorPage, Maintenance } from '../modules/main'
import { Sales, SearchByStore as Search, ShoppingList } from '../modules/products'
import { MarkdownFile } from '../modules/markdown'
import {
  EmailSigninForm,
  RequestPasswordReset,
  RequestPROK,
  RequireAuth,
  ResetPassword,
  SessionAuth,
  Index as SignIn,
  Unauthorized,
} from '../modules/auth'
import {
  Account,
  ArchivedList,
  ArchivedLists,
  Coupon,
  DeleteAccount,
  DeleteAccountConfirmation,
  Details,
  EditEmail,
  EditPasswd,
  Index,
  PaymentMethod,
  Plan,
  Portal,
  Preferences,
  Profile,
  StoreFavs,
  SubscriptionDetails,
  Support,
  UserAddress,
} from '../modules/user'
import { isMaintenanceMode } from '../utils'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

// eslint-disable-next-line react-refresh/only-export-components
let IndexEle: JSX.Element
if (isMaintenanceMode()) {
  IndexEle = <Maintenance />
} else {
  IndexEle = (
    <RequireAuth>
      <App />
    </RequireAuth>
  )
}

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: IndexEle,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Sales />,
      },
      {
        path: 'shopping-list',
        element: <ShoppingList />,
      },
      {
        path: 'search?/:term',
        element: <Search />,
      },
    ],
  },
  {
    path: 'signin',
    element: <SignIn />,
  },
  {
    path: 'email-signin',
    element: <EmailSigninForm />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
  },
  {
    path: 'request-prok',
    element: <RequestPROK />,
  },
  {
    path: 'forgot-password',
    element: <RequestPasswordReset />,
  },
  {
    path: 'sess-auth/:sessId',
    element: <SessionAuth />,
  },
  {
    path: 'f/:file',
    element: <MarkdownFile />,
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
  {
    path: 'delete-account-confirmation',
    element: <DeleteAccountConfirmation />,
  },
  {
    path: 'me',
    element: (
      <RequireAuth>
        <Index />
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <Profile />,
      },
      {
        path: 'account',
        element: <Account />,
        children: [
          {
            index: true,
            element: <Details />,
          },
          {
            path: 'delete-account',
            element: <DeleteAccount />,
          },
          {
            path: 'details',
            element: <Details />,
          },
          {
            path: 'coupon',
            element: <Coupon />,
          },
          {
            path: 'plan',
            element: <Plan />,
          },
          {
            path: 'portal',
            element: <Portal />,
          },
          {
            path: 'payment-method',
            element: <PaymentMethod />,
          },
          {
            path: 'subscription-details',
            element: <SubscriptionDetails />,
          },
        ],
      },
      {
        path: 'store-favs',
        element: <StoreFavs />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'preferences',
        element: <Preferences />,
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'edit-email',
        element: <EditEmail />,
      },
      {
        path: 'edit-passwd',
        element: <EditPasswd />,
      },
      {
        path: 'saved-lists',
        element: <ArchivedLists />,
      },
      {
        path: 'archived-list/:listId',
        element: <ArchivedList />,
      },
      {
        path: 'user-address',
        element: <UserAddress />,
      },
    ],
  },
])
