import { Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { useCookies } from 'react-cookie'

import { COOKIE_ACCEPTED_BETA_NOTICE, SUPPORT_EMAIL } from '../../../config'
import { SecButStyle, TextLinkStyle } from '../../../styles'

export function BetaNotice(): JSX.Element {
  const [show, setShow] = useState(false)
  const [cookies, setCookie] = useCookies([COOKIE_ACCEPTED_BETA_NOTICE])
  const email = `mailto:${SUPPORT_EMAIL}?subject=Savvie Support`

  const handleConfirm = (): void => {
    setCookie(COOKIE_ACCEPTED_BETA_NOTICE, true, { path: '/' })
    setShow(false)
  }

  useEffect(() => {
    if (cookies[COOKIE_ACCEPTED_BETA_NOTICE] === undefined) {
      setShow(true)
    }
  }, [cookies])

  return (
    <Transition
      show={show}
      as={Fragment}
      enter='transform ease-out duration-300 transition'
      enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
      enterTo='translate-y-0 opacity-100 sm:translate-x-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-primary-100 p-3 ring-1 ring-primary-900/30 md:flex-row md:items-center lg:px-8'>
        <div className='max-w-4xl'>
          <h3 className='text-lg font-semibold text-primary-800'>Hello Savvie shopper!</h3>
          <p className='leading-5 text-sm text-slate-900'>
            We are currently in the beta stage of development and are pretty busy improving various
            features.
          </p>
          <p className='leading-5 text-sm text-slate-900'>
            We appreciate your feedback. Feel free to email us anytime at{' '}
            <a className={TextLinkStyle} href={email}>
              support@buysavvie.com
            </a>
            . Or leave a note using the &apos;Send Feedback&apos; button at the bottom RHS of the
            screen. We appreciate your patience at this time.
          </p>
        </div>
        <div className='flex flex-none items-center gap-x-5'>
          <button
            type='button'
            className={`${SecButStyle} w-32`}
            onClick={() => {
              handleConfirm()
            }}
          >
            Got it!
          </button>
        </div>
      </div>
    </Transition>
  )
}

export default BetaNotice
