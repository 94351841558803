import { PlusIcon } from '@heroicons/react/20/solid'
import { useApolloClient, useMutation } from '@apollo/client'

import { LIST_PRODUCT_FIELDS, SET_SHOPLIST_ITEM } from '../graphql'
import { IProduct } from '../types'
import { useAppSelector } from '../../../config'
import { PrimButStyle } from '../../../styles'

interface IButtonProps {
  product: IProduct
  qty: number
  closeModalFn?: () => void
}

export function AddToListbutton({ product, qty, closeModalFn }: IButtonProps): JSX.Element {
  const client = useApolloClient()
  const shopListId = useAppSelector((state) => state.shopList.listId)

  const closeModal = closeModalFn || (() => {})
  const [setShopListItem] = useMutation(SET_SHOPLIST_ITEM, {})

  const handleAddItem = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()

    if (shopListId === null) {
      throw new Error('shopListId is null')
    }
    const itemSubTotal = product.bestPrice * qty

    client.cache.modify({
      id: client.cache.identify({ __typename: 'ShopList', _id: shopListId }),
      fields: {
        totalCost(existingTotalCost = 0) {
          return existingTotalCost + itemSubTotal
        },
        items(existingItemRefs = [], { readField }) {
          const existingItem = existingItemRefs.find(
            (ref: any) => readField('_id', ref) === product._id,
          )
          if (existingItem) {
            const newQty = qty + Number(readField('quantity', existingItem))

            client.cache.writeFragment({
              fragment: LIST_PRODUCT_FIELDS,
              fragmentName: 'ListProductFields',
              data: {
                __typename: 'ShopListItem',
                _id: product._id,
                product: {
                  __typename: 'Product',
                  ...product,
                },
                quantity: newQty,
                size: product.sizeText,
                subTotal: product.bestPrice * newQty,
                title: product.title,
                unitPrice: product.bestPrice,
              },
            })
            return [...existingItemRefs]
          } else {
            const newItemRef = client.cache.writeFragment({
              fragment: LIST_PRODUCT_FIELDS,
              fragmentName: 'ListProductFields',
              data: {
                __typename: 'ShopListItem',
                _id: product._id,
                product: {
                  __typename: 'Product',
                  ...product,
                },
                quantity: qty,
                size: product.sizeText,
                subTotal: product.bestPrice * qty,
                title: product.title,
                unitPrice: product.bestPrice,
              },
            })
            return [...existingItemRefs, newItemRef]
          }
        },
      },
    })

    closeModal()
    setShopListItem({
      variables: { input: { listId: shopListId, itemId: product._id, quantity: qty } },
    })
  }

  return (
    <button className={PrimButStyle} onClick={handleAddItem}>
      Add to List
      <PlusIcon className='stroke-white w-5 pl-1' />
    </button>
  )
}
