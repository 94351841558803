interface TitleProps {
  title: string
}
export function Title({ title }: TitleProps): JSX.Element {
  return <h1 className='text-center text-3xl text-slate-600 font-base'> {title} </h1>
}

interface ContainerProps {
  children: JSX.Element | JSX.Element[] | boolean
}
export function Container({ children }: ContainerProps): JSX.Element {
  return (
    <div className='max-w-md mx-auto sm:mt-8 sm:rounded-2xl pt-6 px-8 pb-10 bg-white border border-slate-400 sm:shadow-lg sm:shadow-slate-400'>
      {children}
    </div>
  )
}

interface ErrMsgProps {
  errStr: string
}
export function ErrMsg({ errStr }: ErrMsgProps): JSX.Element {
  return <p className='mt-1 ml-1 -mb-3 text-sm text-red-600'>{errStr}</p>
}
