import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ls from 'localstorage-slim'

import { SHOP_LIST_ID_KEY } from '../../config'
import { IProduct } from './types'

interface IShopListInput {
  _id: string
  items: IShopListItemInput[]
  totalCost: number
}

interface IShopListItemInput {
  productId: string
  quantity: number
  subTotal: number
  unitPrice: number
  product: IProduct
}

interface IShopListItem {
  productId: string
  quantity: number
  subTotal: number
  unitPrice: number
  title: string
}

interface IShopList {
  _id: string
  items: IShopListItem[]
  quantity: number
  totalCost: number
}

export interface ShopListState {
  listId: string | null
  list: IShopList | null
}

const initialState: ShopListState = {
  listId: null,
  list: null,
}

export const shopListSlice = createSlice({
  name: 'shopList',
  initialState,
  reducers: {
    deleteShopList: (state) => {
      ls.remove(SHOP_LIST_ID_KEY)
      state.listId = null
      state.list = null
    },
    getShopListId: (state) => {
      state.listId = ls.get(SHOP_LIST_ID_KEY)
    },
    // FIXME: check if this is actually used -- suspect not...
    setShopList: (state, action: PayloadAction<IShopListInput>) => {
      const items = action.payload.items.map((item) => ({
        productId: item.productId,
        quantity: item.quantity,
        subTotal: item.subTotal,
        unitPrice: item.unitPrice,
        title: item.product.title,
      }))
      const shopList: IShopList = {
        _id: action.payload._id,
        items,
        quantity: items.reduce((acc, item) => acc + item.quantity, 0),
        totalCost: action.payload.totalCost,
      }
      state.list = { ...shopList } // not really sure this is needed -- an attempt to ensure we're properly updating the state
      state.listId = action.payload._id
      ls.set(SHOP_LIST_ID_KEY, action.payload._id)
    },
    setShopListId: (state, action: PayloadAction<string>) => {
      ls.set(SHOP_LIST_ID_KEY, action.payload)
      state.listId = action.payload
    },
    deleteShopListId: (state) => {
      ls.remove(SHOP_LIST_ID_KEY)
      state.listId = null
    },
  },
})

export const { deleteShopList, deleteShopListId, getShopListId, setShopList, setShopListId } =
  shopListSlice.actions

export default shopListSlice.reducer
