import React, { ReactNode } from 'react'

import { Heading } from '../shared'

interface ContainersProps {
  children: ReactNode
  heading?: string
}

export const SectionContainer: React.FC<ContainersProps> = ({ children, heading }) => {
  return (
    <div className='pt-6'>
      {heading !== undefined && <Heading text={heading} />}
      {children}
    </div>
  )
}

export default SectionContainer
