import { Link } from 'react-router-dom'

import logo from '../../../assets/images/logo.svg'
import { ProfileMenu } from '../'

export function HeaderWithProfile(): JSX.Element {
  return (
    <header className='flex bg-white items-center h-12 sm:h-20 px-4 shadow-sm shadow-slate-300'>
      <Link to='/'>
        <img className='relative h-10 sm:h-16' src={logo} alt='Savvie' />
      </Link>
      <div className='flex-grow'> </div>
      <ProfileMenu />
    </header>
  )
}

export default HeaderWithProfile
