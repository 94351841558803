export const ITEM_ADDED = 'Item added'
export const ITEM_DELETED = 'Item removed'
export const ITEM_UPDATED = 'Item updated'

export const LIST_ACTIVATED = 'Shopping list activated'
export const LIST_DELETED = 'Shopping list deleted'

export const PROFILE_PERSONAL_INFO_UPDATED = 'Personal information updated.'
export const PROFILE_EMAIL_UPDATED = 'Email updated.'
export const PROFILE_PASSWORD_UPDATED = 'Password updated.'
export const PROFILE_SUBMISSION_FAILED = 'Submission failed.'
