import { useLocation, Navigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../config'
import { getUserSession, IUserSession } from '../authSlice'

export function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const dispatch = useAppDispatch()
  const location = useLocation()

  let userSession: IUserSession | null = null
  if (useAppSelector((state) => state.auth.userSession) === null) {
    dispatch(getUserSession())
  }
  userSession = useAppSelector((state) => state.auth.userSession)

  if (userSession == null) {
    // Redirect them to the /signin page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/signin' state={{ from: location }} replace />
  }

  return children
}
