import { GoogleOAuthProvider } from '@react-oauth/google'

import { HeaderCentered as Header } from '../../../layout/index'
import { Container } from '../Common'
import { AppleSigninButton, EmailSigninButton, GoogleSigninButton } from '../../'

export function Index(): JSX.Element {
  return (
    <div className='bg-white h-screen md:bgimg'>
      <Header />
      <Container>
        <h1 className='text-center text-3xl text-slate-700 font-semibold'>Sign in to Savvie</h1>
        <div className='flex flex-col gap-y-2 mt-4'>
          <h1>Continue with:</h1>
          <div>
            <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
              <GoogleSigninButton />
            </GoogleOAuthProvider>
          </div>
          <div>
            <AppleSigninButton />
          </div>
          <div>
            <EmailSigninButton />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Index
