import { useQuery } from '@apollo/client'

import { STRIPE_GET_PORTAL_SESSION } from '../../graphql'
import { LoaderModal } from '../../../common'

export function Portal(): JSX.Element {
  useQuery(STRIPE_GET_PORTAL_SESSION, {
    onError: (error) => {
      console.log('error: ', error)
    },
    onCompleted: (data) => {
      if (data?.stripeGetPortalSession?.url != null) {
        window.location.href = data.stripeGetPortalSession.url
      }
    },
  })

  return (
    <div className='flex flex-col divide-y-slate-400 divide-y space-y-6'>
      <div className=''>
        <LoaderModal isOpen={true} message='Stand by redirecting to Stripe portal...' />
      </div>
    </div>
  )
}

export default Portal
