import React, { FC } from 'react'

import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import { GET_USER_ACCOUNT_INFO } from '../../graphql/queries'
import { Heading } from '../shared'
import { LoaderModal } from '../../../common'
import { TextLinkStyle, UtilSecButtonStyle } from '../../../../styles/common'
import { parseUTCDate } from '../../../../utils'

interface SubsInfo {
  active: string
  status: string
  lastUpdated: string
  name: string
  email: string
  haveDefaultPayment?: string
  haveDiscount: string
  plan: string
  trialEnd?: string
}

const MessageWrapper: React.FC<{ component: FC | undefined }> = ({ component: Component }) => {
  if (Component == null) return <></>
  return <Component />
}

// TODO: handle cancelled status
function setMessage(active: boolean, status: string): FC | undefined {
  if (!active && status === 'paused') {
    // eslint-disable-next-line react/display-name
    return () => (
      <div className='max-w-lg bg-yellow-100 rounded-lg p-4 shadow'>
        <div className='flex flex-row text-slate-900 font-semibold mb-3'>
          <ExclamationTriangleIcon className='h-6 w-6 mr-2 text-red-600' aria-hidden='true' />{' '}
          Important notice regarding your account status
        </div>
        <div className='text-sm text-slate-800 leading-4'>
          <p className='mb-3'>
            Currently, you do not have an active subscription as the status is now paused.
          </p>
          <p className='font-medium'>
            <Link to='payment-method' className={TextLinkStyle}>
              Add a payment method
            </Link>{' '}
            and reactivate your subscription.
          </p>
        </div>
      </div>
    )
  }
  if (status === 'trialing') {
    // eslint-disable-next-line react/display-name
    return () => (
      <div className='max-w-lg bg-yellow-100 rounded-lg p-4 shadow'>
        <div className='flex flex-row text-slate-900 font-semibold mb-3'>
          <ExclamationTriangleIcon className='h-6 w-6 mr-2 text-red-600' aria-hidden='true' />{' '}
          Important notice regarding your account status
        </div>
        <div className='text-sm text-slate-800 leading-4'>
          <p className='mb-3'>
            Currently, you&apos;re subscription is in a <b>trial status</b>.
          </p>
          <p className='font-medium'>
            <Link to='payment-method' className={TextLinkStyle}>
              Add a payment method
            </Link>{' '}
            and avoid any interruptions.
          </p>
        </div>
      </div>
    )
  }
}

export function SubscriptionDetails(): JSX.Element {
  // FIXME: we can fetch this data from the cache as in: const data = client.readQuery({ query: GET_USER_ACCOUNT_INFO })
  // see the Details.tsx file for an example
  const { data, loading } = useQuery(GET_USER_ACCOUNT_INFO)

  if (loading) {
    return <LoaderModal isOpen={loading} message='Stand by fetching your account information' />
  }
  console.log('data: ', data)
  if (data == null) {
    return <></>
  }

  const firstName: string = data.getUser.firstName
  const lastName: string = data.getUser.lastName
  const email: string = data.getUser.email
  const couponId: string | null = data.getUser.subscription.stripe.couponId
  const isStripeCustomer: boolean = data.getUser.subscription.stripe.customerId

  const subsInfo: SubsInfo = {
    active: data.getUser.subscription.active ? 'Yes' : 'No',
    status: data.getUser.subscription.status,
    lastUpdated: format(parseUTCDate(data.getUser.subscription.updateTS), 'MMM do yyyy'),
    // lastUpdated: 'last updated',
    name: `${firstName} ${lastName}`,
    email,
    plan: data.getUser.subscription.planName,
    // haveDefaultPayment:
    //   data.stripeGetSubscription.subscription.defaultPaymentMethod !== null ? 'Yes' : 'No',
    haveDiscount: couponId !== null ? couponId : 'None',
    trialEnd: format(parseUTCDate(data.getUser.subscription.trial.endTS), 'MMM do yyyy'),
  }

  return (
    <div className='flex flex-col'>
      <div className=''>
        <Heading text='Subscription' />
        <div className='mt-4 space-y-6 flex flex-col '>
          <table className='max-w-lg divide-y divide-slate-300'>
            <tbody className='divide-y divide-slate-200'>
              <tr>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>Name</td>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700'>
                  {subsInfo.name}
                </td>
              </tr>
              <tr>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>Email</td>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700'>
                  {subsInfo.email}
                </td>
              </tr>
              <tr>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>Plan</td>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700'>
                  {subsInfo.plan}
                </td>
              </tr>
              <tr>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>Active</td>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700'>
                  {subsInfo.active}
                </td>
              </tr>
              <tr>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>Status</td>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700 capitalize'>
                  {subsInfo.status}
                </td>
              </tr>
              {subsInfo.status === 'trialing' && (
                <tr>
                  <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>
                    Trial End Date
                  </td>
                  <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700'>
                    {subsInfo.trialEnd}
                  </td>
                </tr>
              )}
              <tr>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>Coupon</td>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700'>
                  {subsInfo.haveDiscount}
                </td>
              </tr>
              {/*  <tr>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>
                  Default Payment Method
                </td>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700'>
                  {subsInfo.haveDefaultPayment}{' '}
                  <Link to='payment-method' className={TextLinkStyle}>
                    [update]
                  </Link>
                </td>
              </tr> */}
              <tr>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-800'>Last Updated</td>
                <td className='whitespace-nowrap px-3 py-3 text-sm text-slate-700'>
                  {subsInfo.lastUpdated}
                </td>
              </tr>
            </tbody>
          </table>

          {
            <MessageWrapper
              component={setMessage(data.getUser.subscription.active, subsInfo.status)}
            />
          }
          {isStripeCustomer ? (
            <div className='max-w-lg flex flex-col sm:flex-row sm:justify-evenly gap-3'>
              <Link to='coupon' className={`${UtilSecButtonStyle} sm:w-1/2`}>
                Enter coupon
              </Link>
              <Link to='portal' className={`${UtilSecButtonStyle} sm:w-1/2 `}>
                Manage subscription
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionDetails
