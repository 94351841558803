import logo from '../../../assets/images/logo.svg'

export function HeaderCentered(): JSX.Element {
  return (
    <header className='flex justify-center items-center pt-2 sm:py-6'>
      <img className='relative h-20 sm:h-24' src={logo} alt='Savvie' />
    </header>
  )
}

export default HeaderCentered
