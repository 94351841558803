import { useEffect } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { BasicHeader } from '../../layout'
import { GET_USER_SESSION } from '../graphql'
import { LoaderModal } from '../../common'
import { setUserSession } from '../authSlice'
import { useAppDispatch } from '../../../config'

export function SessionAuth(): JSX.Element {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { sessId } = useParams()

  const { loading, error, data } = useQuery(GET_USER_SESSION, {
    variables: { sessionId: sessId },
  })

  // console.log('error: ', error?.graphQLErrors[0].message)

  useEffect(() => {
    if (data !== undefined) {
      dispatch(setUserSession(data.getUserSession))
      navigate('/', { replace: true })
    }
  }, [data, dispatch, navigate])

  return (
    <>
      <BasicHeader />
      <div className='flex bg-primary-100 h-screen'>
        {error !== undefined && (
          <div className='flex-grow bg-red-200 h-14 rounded-md mx-10 mt-10 p-4 font-md text-lg'>
            An error occurred and we&apos;re looking into it.
          </div>
        )}
      </div>
      <LoaderModal isOpen={loading} message="Stand by we're authenticating you" />
    </>
  )
}

export default SessionAuth
