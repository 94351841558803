import React from 'react'

import { Link } from 'react-router-dom'

import { LinkedHeader as Header, Footer } from '../../layout/index'
import { TextLinkStyle } from '../../../styles'

export const Unauthorized: React.FC = () => {
  return (
    <>
      <Header />
      <div className='h-screen w-1/2 mx-auto'>
        <h1 className='text-3xl font-semibold mb-4'>Unauthorized</h1>
        <p>You are not authorized to view this page.</p>
        <p className='flex flex-col pt-3 space-y-2 text-lg font-semibold'>
          <Link to='/signin' className={TextLinkStyle}>
            Sign In
          </Link>
          <Link to='/' className={TextLinkStyle}>
            Go Shopping
          </Link>
        </p>
      </div>
      <Footer />
    </>
  )
}

export default Unauthorized
